<style>
    @media (max-width: 1024px) {
        .responsive-table {
            min-width: 1000px !important;
        }
    }
</style>

<template>
  <div class="overflow-auto" style="max-height: 75vh;">
    <TableContainer>
      <table
        v-if="this.averageAndTotalStats"
        class="min-w-full divide-y divide-gray-300 responsive-table"
      >
        <thead>
          <tr class="grid grid-cols-11">
            <TableHeader
              :sortColumn="sortColumn"
              :sortOrder="sortOrder"
              columnKey="name"
              @sort="handleSort"
            >
            </TableHeader>
            <TableHeader
              :sortColumn="sortColumn"
              :sortOrder="sortOrder"
              columnKey="sales_count"
              @sort="handleSort"
            >
              Contrats vendus
            </TableHeader>
            <TableHeader
              :sortColumn="sortColumn"
              :sortOrder="sortOrder"
              columnKey="sales_count_target"
              @sort="handleSort"
            >
              Objectif
            </TableHeader>
            <TableHeader
              :sortColumn="sortColumn"
              :sortOrder="sortOrder"
              columnKey="sales_count_result_target"
              @sort="handleSort"
            >
              Atteinte de l’objectif
            </TableHeader>
            <TableHeader
              :sortColumn="sortColumn"
              :sortOrder="sortOrder"
              :style="'border-left: 4px solid #ccc'"
              columnKey="sales_count_projection"
              @sort="handleSort"
            >
              Contrats vendus
            </TableHeader>
            <TableHeader
              :sortColumn="sortColumn"
              :sortOrder="sortOrder"
              columnKey="sales_count_projection_target"
              @sort="handleSort"
            >
              Atteinte anticipée de l’objectif
            </TableHeader>
            <TableHeader
              :sortColumn="sortColumn"
              :sortOrder="sortOrder"
              :style="'border-right: 4px solid #ccc'"
              columnKey="sales_count_projection_variation"
              @sort="handleSort"
            >
              Évolution M-1
            </TableHeader>
            <TableHeader
              :sortColumn="sortColumn"
              :sortOrder="sortOrder"
              columnKey="lead_count"
              @sort="handleSort"
            >
              Leads créés
            </TableHeader>
            <TableHeader
              :sortColumn="sortColumn"
              :sortOrder="sortOrder"
              columnKey="lead_conversion_rate"
              @sort="handleSort"
            >
              Taux de conversion des leads
            </TableHeader>
            <TableHeader
              :sortColumn="sortColumn"
              :sortOrder="sortOrder"
              columnKey="cancellation_rate"
              @sort="handleSort"
            >
              Taux de résiliation
            </TableHeader>
            <TableHeader
              :sortColumn="sortColumn"
              :sortOrder="sortOrder"
              columnKey="monthly_sales_rate"
              @sort="handleSort"
            >
              Part de policies mensuelles
            </TableHeader>
          </tr>
        </thead>
        <StatsTable
          :averageAndTotalStats="this.averageAndTotalStats"
          grid-cols="grid-cols-11"
        />
      </table>
    </TableContainer>
    <table class="min-w-full divide-y divide-gray-300 responsive-table sticky	top-0 z-50">
      <thead>
        <tr class="grid grid-cols-11">
          <th
            scope="col"
            class="col-span-4 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 border-solid sticky left-0 top-0"
          ></th>
          <th
            scope="col"
            class="col-span-3 px-3 py-6 text-left text-sm-xl font-semibold text-gray-900 text-center bg-gray-200"
          >
            Projection {{ currentMonth }}
          </th>
          <th
            scope="col"
            class="col-span-4 px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          ></th>
        </tr>
      </thead>
    </table>
    <TableContainer>
      <table class="min-w-full divide-y divide-gray-300 responsive-table sticky	top-0 z-50">
        <thead>
          <tr class="grid grid-cols-11">
            <TableHeader
              :sortColumn="sortColumn"
              :sortOrder="sortOrder"
              :style="'background-color: rgba(249, 250, 251, var(--tw-bg-opacity)); position: sticky; left: 0px;'"
              columnKey="name"
              @sort="handleSort"
            >
              Nom
            </TableHeader>
            <TableHeader
              :sortColumn="sortColumn"
              :sortOrder="sortOrder"
              :style="'background-color: rgba(249, 250, 251, var(--tw-bg-opacity));'"
              columnKey="sales_count"
              @sort="handleSort"
            >
              Contrats vendus
            </TableHeader>
            <TableHeader
              :sortColumn="sortColumn"
              :sortOrder="sortOrder"
              :style="'background-color: rgba(249, 250, 251, var(--tw-bg-opacity));'"
              columnKey="sales_count_target"
              @sort="handleSort"
            >
              Objectif
            </TableHeader>
            <TableHeader
              :sortColumn="sortColumn"
              :sortOrder="sortOrder"
              :style="'background-color: rgba(249, 250, 251, var(--tw-bg-opacity));'"
              columnKey="sales_count_result_target"
              @sort="handleSort"
            >
              Atteinte de l’objectif
            </TableHeader>
            <TableHeader
              :sortColumn="sortColumn"
              :sortOrder="sortOrder"
              :style="'border-left: 4px solid #ccc; background-color: rgba(249, 250, 251, var(--tw-bg-opacity));'"
              columnKey="sales_count_projection"
              @sort="handleSort"
            >
              Contrats vendus
            </TableHeader>
            <TableHeader
              :sortColumn="sortColumn"
              :sortOrder="sortOrder"
              :style="'background-color: rgba(249, 250, 251, var(--tw-bg-opacity));'"
              columnKey="sales_count_projection_target"
              @sort="handleSort"
            >
              Atteinte anticipée de l’objectif
            </TableHeader>
            <TableHeader
              :sortColumn="sortColumn"
              :sortOrder="sortOrder"
              :style="'border-right: 4px solid #ccc; background-color: rgba(249, 250, 251, var(--tw-bg-opacity));'"
              columnKey="sales_count_projection_variation"
              @sort="handleSort"
            >
              Évolution M-1
            </TableHeader>
            <TableHeader
              :sortColumn="sortColumn"
              :sortOrder="sortOrder"
              :style="'background-color: rgba(249, 250, 251, var(--tw-bg-opacity));'"
              columnKey="lead_count"
              @sort="handleSort"
            >
              Leads créés
            </TableHeader>
            <TableHeader
              :sortColumn="sortColumn"
              :sortOrder="sortOrder"
              :style="'background-color: rgba(249, 250, 251, var(--tw-bg-opacity));'"
              columnKey="lead_conversion_rate"
              @sort="handleSort"
            >
              Taux de conversion des leads
            </TableHeader>
            <TableHeader
              :sortColumn="sortColumn"
              :sortOrder="sortOrder"
              :style="'background-color: rgba(249, 250, 251, var(--tw-bg-opacity));'"
              columnKey="cancellation_rate"
              @sort="handleSort"
            >
              Taux de résiliation
            </TableHeader>
            <TableHeader
              :sortColumn="sortColumn"
              :sortOrder="sortOrder"
              :style="'background-color: rgba(249, 250, 251, var(--tw-bg-opacity));'"
              columnKey="monthly_sales_rate"
              @sort="handleSort"
            >
              Part de policies mensuelles
            </TableHeader>
          </tr>
        </thead>
      </table>
      <table class="min-w-full divide-y divide-gray-300 responsive-table">
        <StatsTable
          :averageAndTotalStats="this.storeStats"
          grid-cols="grid-cols-11"
          cursor-pointer="cursor-pointer"
          :onRowClick="handleStoreClick"
        />
      </table>
    </TableContainer>
  </div>
</template>
<script>
import { formatVariation } from "@/utils/variation_formatter";
import TableHeader from "@/components/analytics/retail/utils/TableHeader.vue";
import StatsTable from "@/components/analytics/retail/utils/StatsTable.vue";
import TableContainer from "@/components/analytics/retail/utils/TableContainer.vue";

export default {
  components: {
    TableContainer,
    TableHeader,
    StatsTable,
  },

  props: [
    "storeStats",
    "currentMonth",
    "numberOfStores",
    "averageAndTotalStats",
  ],

  data: () => {
    return {
      sortColumn: null,
      sortOrder: null,
    };
  },

  methods: {
    handleStoreClick(storeId) {
      this.$emit("storeIdSelected", storeId);
    },

    handleSort(column) {
      if (this.sortColumn === column) {
        this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = column;
        this.sortOrder = "asc";
      }

      this.emitSortEvent();
    },

    emitSortEvent() {
      this.$emit("sortChanged", {
        column: this.sortColumn,
        order: this.sortOrder,
      });
    },

    formatVariation,
  },
};
</script>
