import { securedAxiosInstance } from "../axios";

class AnalyticsService {
  getRetailSalesData(startDate, endDate, sortParams) {
    return securedAxiosInstance.get(
      `/admin/analytics/retail/sales` +
        `?sortColumn=${sortParams["column"]}&sortOrder=${sortParams["order"]}`, {
          params: {
            start: startDate,
            end: endDate,
          }
        }
    );
  }

  getRetailSellersData(storeId, startDate, endDate, sortParams) {
    return securedAxiosInstance.get(
      `/admin/analytics/retail/sellers` +
        `?store_id=${storeId}&sortColumn=${sortParams["column"]}&sortOrder=${sortParams["order"]}`, {
          params: {
            start: startDate,
            end: endDate,
          }
        }
    );
  }

  getGeneralAnalytics({ startDate, endDate }) {
    return securedAxiosInstance.get("/admin/analytics", {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    });
  }
}

export default new AnalyticsService();
