<template>
  <div class="tooltip-box">
    <div class="informationIcon">
      <InformationCircleIcon class="h-5 w-4 text-primary-dark" aria-hidden="true" />
    </div>
    <div class="tooltip">
      <span class="text text-xs">{{ text }}</span>
    </div>
  </div>
</template>

<script>
import { InformationCircleIcon } from "@heroicons/vue/outline";

export default {
  components: {
    InformationCircleIcon,
  },

  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.tooltip-box {
  position: relative;
  display: inline-block;
}

.informationIcon:hover + .tooltip {
  display: block;
}

.tooltip {
  color: gray;
  font-weight: 200 !important;
  line-height: 0.5;
  text-align: center;
  padding: 5px;
  border-radius: 4px;
  border-color: gray;
  box-shadow: 1px 1px 0.5px 0.5px gray;

  width: 260px;
  bottom: 100%;
  left: 50%;
  margin-left: -130px;
  transition: opacity 1s;

  position: absolute;
  z-index: 3;

  background: white;
  display: none;
}

.text::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: gray transparent transparent transparent;
}
</style>
