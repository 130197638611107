<template>
  <table class="min-w-full divide-y divide-gray-300 responsive-table">
    <thead>
      <tr class="grid grid-cols-8">
        <TableHeader
          v-for="column in columns"
          :key="column.columnKey"
          :sortColumn="sortColumn"
          :sortOrder="sortOrder"
          :columnKey="column.columnKey"
          :style="column.style"
          @sort="handleSort"
        >
          {{ column.label }}
        </TableHeader>
      </tr>
    </thead>
    <tbody class="divide-y divide-gray-200">
      <tr
        v-for="item in averageAndTotalStats"
        :key="item.name"
        :class="`${isStringValid(cursorPointer) ? 'bg-gray-100' : 'bg-white'} grid ${gridCols}`"
      >
        <td
          class="break-words flex items-center col-span-1 py-2 pl-2 text-xs font-medium text-gray-900 sticky left-0 bg-white hover:bg-gray-50"
          style="border-bottom: 0.25px solid #ccc"
        >
          {{ item.name }}
        </td>
        <td
          class="flex items-center col-span-1 px-2 py-2 text-xs text-gray-500 pl-5"
          style="border-bottom: 0.25px solid #ccc"
        >
          {{ item.sales_count }}
        </td>
        <td
          class="flex items-center col-span-1 px-2 py-2 text-xs text-gray-500"
          style="border-bottom: 0.25px solid #ccc; border-left: 4px solid #ccc;"
          v-if="isStringValid(item.sales_count_projection)"
        >
          {{ item.sales_count_projection }}
        </td>
        <td
          class="flex items-center col-span-1 px-2 py-2 text-xs text-gray-500"
          style="border-bottom: 0.25px solid #ccc; border-right: 4px solid #ccc;"
          v-if="isStringValid(item.sales_count_projection_variation)"
        >
          <div
            class="inline-block px-2 py-1 rounded-xl"
            :class="{
              'bg-green-100 text-green-500 border border-dashed border-green-300 ':
                item.sales_count_projection_variation > 80,
              'bg-yellow-100 text-yellow-500 border border-dashed border-yellow-300':
                item.sales_count_projection_variation >= 50,
              'bg-red-100 text-red-500 border border-dashed border-red-300':
                item.sales_count_projection_variation < 50,
            }"
          >
            {{ item.sales_count_projection_variation }} %
          </div>
        </td>
        <td
          class="flex items-center col-span-1 px-2 py-2 text-xs text-gray-500"
          style="border-bottom: 0.25px solid #ccc"
        >
          {{ item.lead_count }}
        </td>
        <td
          class="flex items-center col-span-1 px-2 py-2 text-xs text-gray-500"
          style="border-bottom: 0.25px solid #ccc"
        >
          {{ item.lead_conversion_rate }} %
        </td>
        <td
          class="flex items-center col-span-1 px-2 py-2 text-xs text-gray-500"
          style="border-bottom: 0.25px solid #ccc"
        >
          {{ item.cancellation_rate }} %
        </td>
        <td
          class="flex items-center col-span-1 px-2 py-2 text-xs text-gray-500"
          style="border-bottom: 0.25px solid #ccc"
        >
          {{ item.monthly_sales_rate }} %
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import TableHeader from "@/components/analytics/retail/utils/TableHeader.vue";
import { isStringValid } from "@/utils/validation";

export default {
  components: {
    TableHeader
  },
  props: {
    averageAndTotalStats: {
      type: Array,
      required: true,
    },
    gridCols: {
      type: String,
      default: "grid-cols-8",
    },
    cursorPointer: {
      type: String,
      default: "",
    },
    onRowClick: {
      type: Function,
      default: () => {},
    },
    sortColumn: {
      type: String,
      default: null
    },
    sortOrder: {
      type: String,
      default: null
    }
  },
  data: () => {
    return {
      columns: [
        {
          columnKey: "name",
        },
        {
          columnKey: "sales_count",
          label: "Contrats vendus"
        },
        {
          columnKey: "sales_count_projection",
          label: "Contrats vendus",
          style: "border-left: 4px solid #ccc"
        },
        {
          columnKey: "sales_count_projection_variation",
          label: "Évolution M-1",
          style: "border-right: 4px solid #ccc"
        },
        {
          columnKey: "lead_count",
          label: "Leads créés"
        },
        {
          columnKey: "lead_conversion_rate",
          label: "Taux de conversion des leads"
        },
        {
          columnKey: "cancellation_rate",
          label: "Taux de résiliation"
        },
        {
          columnKey: "monthly_sales_rate",
          label: "Part de policies mensuelles"
        }
      ]
    };
  },
  methods: {
    isStringValid,

    handleSort(columnKey) {
      this.$emit("sort", columnKey);
    },
  },
};
</script>
