import { securedAxiosInstance } from "../axios";

class SaleService {
  seeContractDetails(productCategoryName, productPrice, storeId) {
    return securedAxiosInstance.get(
      `/store/${storeId}/plan_sales/simulation` +
        `?productPrice=${productPrice}&productCategory=${productCategoryName}`
    );
  }
}

export default new SaleService();
