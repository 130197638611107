<template>
  <div>
    <div class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div
            class="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
          >
            <table class="min-w-full table-fixed divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="relative w-12 px-6 sm:w-16 sm:px-8">
                    <input
                      v-if="this.atLeastOneProductIsApproved"
                      type="checkbox"
                      class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary-dark focus:ring-primary sm:left-6"
                      :value="this.areAllProductsSelected"
                      :checked="this.areAllProductsSelected"
                      @change="this.handleProductsSelection"
                      :disabled="this.allProductsSelected"
                    />
                  </th>
                  <th
                    scope="col"
                    class="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Name") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Status") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Uploaded to platform") }}
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {{ $t("Product Type") }}
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr
                  v-for="product in getOrderedProducts"
                  :key="product.id"
                  :class="[isProductSelected(product) && 'bg-gray-50']"
                >
                  <td class="relative w-12 px-6 sm:w-16">
                    <div v-if="this.isProductSelected(product)"></div>
                    <input
                      v-if="this.isProductApproved(product)"
                      type="checkbox"
                      class="absolute top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary-dark focus:ring-primary sm:left-6"
                      :value="product.id"
                      :disabled="this.allProductsSelected"
                      @change="this.handleProductSelection($event, product)"
                      :checked="this.isProductSelected(product)"
                    />
                    <UnapprovedProductInformation
                      v-else
                      v-bind:product="product"
                    />
                  </td>
                  <td
                    :class="[
                      'whitespace-nowrap py-4 pr-3 text-sm font-medium',
                      isProductSelected(product)
                        ? 'text-primary-dark'
                        : 'text-gray-900',
                    ]"
                  >
                    {{ product.title }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <FieldSpan
                      v-bind:style-to-add="
                        getStatusFieldDisplayStyle(product.status)
                      "
                      v-bind:value="product.status"
                    />
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <FieldSpan
                      v-bind:style-to-add="
                        getEnabledFieldDisplayStyle(product.enabled)
                      "
                      v-bind:value="product.enabled"
                    />
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ $t(`${product.productType}`) }}
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
                  >
                    <GenericButton
                      class="border-8"
                      @onClick="this.redirectToProductPage(product)"
                    >
                      <EyeIcon class="h-5 w-5" aria-hidden="true" />
                    </GenericButton>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EyeIcon } from "@heroicons/vue/solid";
import GenericButton from "@/components/utils/GenericButton.vue";
import UnapprovedProductInformation from "@/views/store/products/list/content/utils/UnapprovedProductInformation.vue";
import FieldSpan from "@/components/utils/FieldSpan.vue";
export default {
  components: {
    GenericButton,
    EyeIcon,
    UnapprovedProductInformation,
    FieldSpan,
  },
  props: ["products", "selectedProducts", "allProductsSelected"],

  computed: {
    areAllProductsSelected() {
      const arePagedMatchedProductsAllSelected =
        this.selectedProducts.length !== 0 &&
        this.products.filter((p) => this.isProductApproved(p)).length ===
          this.selectedProducts.length;
      return arePagedMatchedProductsAllSelected || this.allProductsSelected;
    },
    getOrderedProducts: function () {
      return [...this.products].sort((a, b) =>
        a.enabled < b.enabled ? 1 : -1
      );
    },
    atLeastOneProductIsApproved: function () {
      return this.products.filter((p) => this.isProductApproved(p)).length > 0;
    },
  },
  methods: {
    getEnabledFieldDisplayStyle: function (isEnabled) {
      return isEnabled
        ? "bg-green-100 text-green-800"
        : "bg-red-100 text-red-800";
    },
    getStatusFieldDisplayStyle: function (status) {
      let result;
      switch (status) {
        case "Pending":
          result = "bg-yellow-100 text-yellow-800";
          break;
        case "Live":
          result = "bg-green-100 text-green-800";
          break;
        case "Matched":
          result = "bg-blue-100 text-blue-800";
          break;
        case "Refused":
          result = "bg-red-100 text-red-800";
          break;
        default:
          result = "bg-blue-100 text-blue-800";
      }
      return result;
    },
    isProductApproved: function (product) {
      return product.status === "Matched";
    },
    redirectToProductPage: function (product) {
      this.$router.push(`/platform/products/${product.id}`);
    },
    isProductSelected: function (product) {
      return (
        this.selectedProducts.includes(product.id) || this.allProductsSelected
      );
    },
    handleProductSelection: function (event, product) {
      if (event.target.checked) {
        this.selectProducts([product]);
      } else {
        this.unselectProducts([product]);
      }
    },
    handleProductsSelection: function (event) {
      if (!this.allProductsSelected) {
        if (event.target.checked) {
          this.selectProducts(
            this.products.filter((p) => this.isProductApproved(p))
          );
        } else {
          this.unselectProducts(this.products);
        }
      }
    },
    selectProducts: function (products) {
      this.$emit("selectProducts", products);
    },
    unselectProducts: function (products) {
      this.$emit("unselectProducts", products);
    },
  },
};
</script>
