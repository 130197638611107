import {
  CheckIcon,
  XCircleIcon,
  InformationCircleIcon,
} from "@heroicons/vue/outline";

let maxId = 0;

const ADD = "ADD_MESSAGE";
const REMOVE = "REMOVE_MESSAGE";
const dismissAfter = 7_500;

const defaultIconComponents = {
  success: CheckIcon,
  error: XCircleIcon,
  info: InformationCircleIcon,
};

const defaultIconColors = {
  success: "green",
  error: "red",
  info: "blue",
};

const defaultMessage = {
  error: {
    title: "An Error Occurred",
    text: "Please try again or contact support via the chat below.",
  },
  success: {
    title: "Success",
    text: "Your request was successful.",
  },
}

class Message {
  constructor({
    id,
    type,
    category,
    title,
    text,
    iconComponent = null,
    iconColor = null,
  }) {
    this.id = id;
    this.type = type;
    this.category = category;
    this.title = title || defaultMessage[this.type]["title"];
    this.text = text || defaultMessage[this.type]["text"];;
    this.iconComponent = iconComponent || defaultIconComponents[this.type];
    this.iconColor = iconColor || defaultIconColors[this.type];
  }
}

export default {
  namespaced: true,

  state: {
    messages: [],
  },

  getters: {
    lastModalMessage: (state) => {
      const modalMessages = state.messages.filter(
        (m) => m.category === "modal"
      );
      return modalMessages.slice(-1)[0];
    },

    simpleMessages: (state) => {
      return state.messages.filter((m) => m.category === "simple");
    },
  },

  mutations: {
    [ADD](state, message) {
      state.messages.push(message);
    },

    [REMOVE](state, id) {
      state.messages = state.messages.filter((m) => m.id !== id);
    },

    CLEAR_ALL(state) {
      state.messages = [];
    },
  },

  actions: {
    notify({ commit }, messageFields) {
      const id = ++maxId;
      const message = new Message({ id, ...messageFields });

      commit(ADD, message);
      if (message.category === "simple") {
        const message = new Message({ id, ...messageFields });
        setTimeout(() => commit(REMOVE, message.id), dismissAfter);
      }
    },

    [REMOVE]({ commit }, id) {
      commit(REMOVE, id);
    },

    clearAll({ commit }) {
      commit("CLEAR_ALL");
    },
  },
};
