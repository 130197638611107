export function isStringValid(input) {
  return input !== undefined && input !== null && input !== "";
}

export function isObjectValid(input) {
  return input !== undefined && input !== null && input !== {} && input !== [];
}

export function isArrayEmpty(input) {
  return isObjectValid(input) && input.length === 0;
}

export function isArrayFull(input) {
  return isObjectValid(input) && input.length > 0;
}

export function isStoreIdValid(storeId) {
  return isStringValid(storeId) && storeId !== "0" && storeId !== 0;
}
