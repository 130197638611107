<template>
  <div
    class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
  >
    <div class="flex-1 flex justify-between">
      <div>
        <a
          href="#"
          class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          v-on:click="changePage(currentPage - 1)"
        >
          Previous
        </a>
        <a
          href="#"
          class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          v-on:click="changePage(currentPage + 1)"
        >
          Next
        </a>
      </div>
    </div>
    <div>
      <p class="text-sm font-bold text-gray-700">
        Page : {{ this.currentPage }}
      </p>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
  },

  methods: {
    changePage(goToPage) {
      if (goToPage !== this.currentPage && goToPage !== 0) {
        this.$emit("changePage", goToPage);
      }
    },
  },
};
</script>
