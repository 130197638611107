<template>
  <div>
    <Combobox as="div" v-model="selectedProduct">
      <ComboboxLabel class="block text-sm text-gray-700">
        Nom du produit <span class="text-red-600">*</span>
      </ComboboxLabel>
      <div class="relative mt-1" ref="comboboxWrapper">
        <form>
          <ComboboxInput
            class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm"
            @change="updateQuery"
            @keydown="blockEnterBehavior"
            :display-value="comboboxInputValue"
            @click="openCombobox"
            autocomplete="off"
          />
        </form>
        <ComboboxButton
          ref="comboboxButton"
          id="combobox-product-button"
          class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
        >
          <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          <button
            v-if="selectedProduct != null || query"
            @click="resetAttributes"
          >
            <XIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
          </button>
        </ComboboxButton>

        <ComboboxOptions
          v-if="query || products.length > 0"
          class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <ComboboxOption
            v-if="query"
            :value="queryProduct"
            v-slot="{ active }"
          >
            <span
              :class="[
                'relative cursor-default select-none py-2 pl-8 pr-4 block truncate text-xs sm:text-base',
                active ? 'text-button-font-primary bg-primary' : 'text-gray-900',
              ]"
            >
              Ajouter "{{ query }}"
            </span>

            <span
              :class="[
                'absolute inset-y-3 left-0 items-center pl-1.5',
                active ? 'text-white' : 'text-primary-dark',
              ]"
            >
              <PlusCircleIcon class="h-5 w-5" aria-hidden="true" />
            </span>
          </ComboboxOption>

          <ComboboxOption
            v-for="product in products"
            :key="product.id"
            :value="product"
            as="template"
            v-slot="{ active, selected }"
          >
            <li
              :class="[
                'relative cursor-default select-none py-2 pl-8 pr-4',
                active ? 'bg-primary text-button-font-primary' : 'text-gray-900',
              ]"
            >
              <span
                :class="[
                  'block truncate',
                  selected && 'font-semibold',
                  'text-xs sm:text-base',
                ]"
              >
                {{ product.title }}
              </span>

              <span
                v-if="selected"
                :class="[
                  'absolute inset-y-0 left-0 flex items-center pl-1.5',
                  active ? 'text-white' : 'text-primary-dark',
                ]"
              >
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ComboboxOption>
        </ComboboxOptions>
      </div>
    </Combobox>
  </div>
</template>

<script>
import {
  CheckIcon,
  SelectorIcon,
  PlusCircleIcon,
  XIcon,
} from "@heroicons/vue/solid";

import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/vue";
import { mapActions, mapState } from "vuex";
import { isObjectValid, isStringValid } from "@/utils/validation";

export default {
  props: ["productName", "productCategory"],

  components: {
    CheckIcon,
    SelectorIcon,
    XIcon,
    PlusCircleIcon,
    Combobox,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
    ComboboxButton,
  },

  computed: {
    ...mapState("store", ["allProducts", "selectedStoreId"]),

    // Filters products with no price, and returns list a products with title/price
    products: function () {
      const products = this.allProducts.filter(
        (product) => product.price !== null
      );
      return [
        ...new Map(
          products.map((product) => [
            product.title,
            product,
          ])
        ).values(),
      ];
    },
    queryProduct: function () {
      return this.query === ""
        ? null
        : { id: null, title: this.query, price: { amount: "" }, reference: "" };
    },
  },

  data() {
    return {
      query: "",
      selectedProduct: null,
      debounce: null,
    };
  },

  mounted() {
    document.addEventListener('mousedown', this.handleClickOutside);
  },

  beforeUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  },

  watch: {
    selectedProduct: function (newSelectedProduct) {
      this.query = "";
      this.$emit("selectProduct", newSelectedProduct);
    },
    query: function () {
      this.debounceSearch();
    },
    productName: function (newProductName) {
      if (!isStringValid(newProductName)) {
        this.resetAttributes();
      }
    },
    selectedStoreId: function (newValue) {
      if (newValue !== 0) {
        this.retrieveProducts();
      }
    },
    productCategory: function () {
      this.retrieveProducts();
    },
  },

  methods: {
    ...mapActions("store", ["getProducts", "resetSearch", "resetFilters"]),

    handleClickOutside(event) {
      if (this.$refs.comboboxWrapper && !this.$refs.comboboxWrapper.contains(event.target) && this.query) {
        this.addProductBasedOnQuery();
      }
    },

    addProductBasedOnQuery() {
      this.selectedProduct = this.queryProduct;
    },

    comboboxInputValue: function (product) {
      return isObjectValid(product) ? product.title : this.query;
    },

    openCombobox() {
      this.$refs.comboboxButton.$el.click();
    },

    resetAttributes: function () {
      this.selectedProduct = null;
      this.query = "";
    },

    debounceSearch: function () {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.retrieveProducts();
      }, 300);
    },

    retrieveProducts: function () {
      try {
        this.resetSearch();
        this.resetFilters();

        const storeId = this.$store.state.store.selectedStoreId;
        const categories = isObjectValid(this.productCategory) ? [this.productCategory.value] : []

        this.getProducts({
          storeId: storeId,
          offline: true,
          title: this.query,
          variants: true,
          categories: categories,
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },

    updateQuery: function (event) {
      this.query = event.target.value;
    },

    blockEnterBehavior: function(event) {
      if (event.key === "Enter") {
        // Block enter behaviour
        event.preventDefault();
      }
    }
  },
};
</script>
