<template>
  <div>
    <transition
      enter-active-class="transition ease-out duration-1000"
      enter-from-class="transform opacity-0 scale-100"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-700"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-100"
    >
      <div class="h-24 w-full" style="background-color: #e9f3ff">
        <div class="mx-auto px-4 sm:px-6 md:px-8 h-full">
          <div class="flex items-center h-full">
            <div class="flex-shrink-0 flex items-center px-4">
              <a href="https://www.estaly.co/">
                <img
                  class="h-12 w-auto"
                  src="@/assets/large_logo_estaly_black.png"
                  alt="Estaly"
                />
              </a>
            </div>
            <transition
              enter-active-class="transition-opacity duration-1000"
              enter-from-class="opacity-0"
              enter-to-class="opacity-100"
              leave-active-class="transition-opacity duration-700"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
            </transition>
          </div>
        </div>
      </div>
    </transition>
    <div class="py-4">
      <div class="max-w-7xl mx-auto px-4">
        <div>
          <div class="pb-4 pl-2">
            <h1 class="text-3xl font-extrabold tracking-tight text-gray-900">
              {{ $t("Welcome!") }}
            </h1>
            <p class="mt-2 text-sm text-gray-500" id="store-type-description">
              {{
                $t(
                  "To start selling insurance policies, please fill in some utils about you and your store."
                )
              }}
            </p>
          </div>
          <VeeForm v-slot="{ handleSubmit }" :validation-schema="schema">
            <form
              @submit="handleSubmit($event, handleGetStarted)"
              ref="handleGetStarted"
            >
              <div class="space-y-6 mb-6">
                <div class="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                  <div class="md:grid md:grid-cols-3 md:gap-6">
                    <div class="md:col-span-1">
                      <h3 class="text-lg font-medium leading-6 text-gray-900">
                        {{ $t("Store") }}
                      </h3>
                      <p class="mt-1 text-sm text-gray-500">
                        {{
                          $t("This is need in order to set up your program.")
                        }}
                      </p>
                    </div>
                    <div class="mt-5 md:mt-0 md:col-span-2">
                      <div class="grid grid-cols-6 gap-6">
                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="store-type"
                            class="block text-sm font-medium text-gray-700"
                            >{{ $t("Store type") }}</label
                          >
                          <Field
                            v-slot="{ value }"
                            name="storeType"
                            as="select"
                            id="store-type"
                            class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                            v-model="storeInfo.storeType"
                          >
                            <option
                              v-for="(storeType, id) in storeTypes"
                              v-bind:key="id"
                              v-bind:value="storeType.value"
                              v-bind:selected="
                                value && value.includes(storeType.value)
                              "
                            >
                              {{ storeType.name }}
                            </option>
                          </Field>
                          <p
                            class="mt-2 text-sm text-gray-500"
                            id="store-type-description"
                          >
                            {{
                              $t(
                                "This will be used to match your products with our contracts"
                              )
                            }}
                          </p>
                          <ErrorMessage
                            name="storeType"
                            class="mt-2 text-xs text-red-600"
                          />
                        </div>

                        <div class="col-span-3">
                          <label
                            for="annual-sales-estimate"
                            class="block text-sm font-medium text-gray-700"
                            >{{ $t("Annual sales estimate") }}</label
                          >
                          <div class="mt-1 relative rounded-md shadow-sm">
                            <div
                              class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                            >
                              <span class="text-gray-500 sm:text-sm"> € </span>
                            </div>
                            <Field
                              type="text"
                              name="annualSalesEstimate"
                              id="annual-sales-estimate"
                              class="focus:ring-primary focus:border-primary block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                              placeholder="5,000,000"
                              v-model="storeInfo.annualSalesEstimate"
                            />
                            <div
                              class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                            >
                              <span
                                class="text-gray-500 sm:text-sm"
                                id="price-currency"
                              >
                                EUR
                              </span>
                            </div>
                          </div>
                          <p
                            class="mt-2 text-sm text-gray-500"
                            id="store-type-description"
                          >
                            {{
                              $t(
                                "This will be used to estimate the revenue you will generate with Estaly."
                              )
                            }}
                          </p>
                          <ErrorMessage
                            name="annualSalesEstimate"
                            class="mt-2 text-xs text-red-600"
                          />
                        </div>

                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="platform"
                            class="block text-sm font-medium text-gray-700"
                            >{{ $t("Platform CMS") }}</label
                          >
                          <Field
                            v-slot="{ value }"
                            name="platform"
                            as="select"
                            id="platform"
                            class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                            v-model="storeInfo.platform"
                          >
                            <option
                              v-for="(platform, id) in platforms"
                              v-bind:key="id"
                              v-bind:value="platform.value"
                              v-bind:selected="
                                value && value.includes(platform.value)
                              "
                            >
                              {{ platform.name }}
                            </option>
                          </Field>
                          <p
                            class="mt-2 text-sm text-gray-500"
                            id="store-platform-description"
                          >
                            {{
                              $t(
                                "This will be used to check if there is a plugin already available for you."
                              )
                            }}
                          </p>
                          <ErrorMessage
                            name="platform"
                            class="mt-2 text-xs text-red-600"
                          />
                        </div>

                        <div class="col-span-3">
                          <label
                            for="store-url"
                            class="block text-sm font-medium text-gray-700"
                            >{{ $t("Store URL") }}</label
                          >
                          <Field
                            type="text"
                            name="storeUrl"
                            id="store-url"
                            class="focus:ring-primary focus:border-primary py-2 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="www.example.com"
                            v-model="storeInfo.storeUrl"
                          />
                          <div
                            class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                          ></div>
                          <p
                            class="mt-2 text-sm text-gray-500"
                            id="store-type-description"
                          >
                            {{
                              $t(
                                "This will be used to check your website configuration and theme."
                              )
                            }}
                          </p>
                          <ErrorMessage
                            name="storeUrl"
                            class="mt-2 text-xs text-red-600"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="space-y-6 mb-6">
                <div class="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                  <div class="md:grid md:grid-cols-3 md:gap-6">
                    <div class="md:col-span-1">
                      <h3 class="text-lg font-medium leading-6 text-gray-900">
                        {{ $t("Personal Information") }}
                      </h3>
                      <p class="mt-1 text-sm text-gray-500">
                        {{
                          $t("This is needed for contact and legal purposes.")
                        }}
                      </p>
                    </div>
                    <div class="mt-5 md:mt-0 md:col-span-2">
                      <div class="grid grid-cols-6 gap-6">
                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="first-name"
                            class="block text-sm font-medium text-gray-700"
                            >{{ $t("First Name") }}</label
                          >
                          <Field
                            type="text"
                            name="firstName"
                            id="first-name"
                            autocomplete="given-name"
                            class="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            v-model="storeInfo.firstName"
                            placeholder="John"
                          />
                          <ErrorMessage
                            name="firstName"
                            class="mt-2 text-xs text-red-600"
                          />
                        </div>

                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="last-name"
                            class="block text-sm font-medium text-gray-700"
                            >{{ $t("Last Name") }}</label
                          >
                          <Field
                            type="text"
                            name="lastName"
                            id="last-name"
                            autocomplete="family-name"
                            class="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            v-model="storeInfo.lastName"
                            placeholder="Doe"
                          />
                          <ErrorMessage
                            name="lastName"
                            class="mt-2 text-xs text-red-600"
                          />
                        </div>

                        <div class="col-span-6">
                          <label
                            for="email"
                            class="block text-sm font-medium text-gray-700"
                            >{{ $t("Email address") }}</label
                          >
                          <Field
                            type="email"
                            name="email"
                            id="email"
                            autocomplete="email"
                            placeholder="example@gmail.com"
                            class="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            v-model="storeInfo.email"
                          />
                          <ErrorMessage
                            name="email"
                            class="mt-2 text-xs text-red-600"
                          />
                        </div>

                        <div class="col-span-6">
                          <label
                            for="phone"
                            class="block text-sm font-medium text-gray-700"
                            >{{ $t("Phone") }}</label
                          >
                          <Field
                            type="tel"
                            name="phone"
                            id="phone"
                            autocomplete="phone-number"
                            placeholder="+33 6 61 12 23 34"
                            class="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            v-model="storeInfo.phone"
                          />
                          <ErrorMessage
                            name="phone"
                            class="mt-2 text-xs text-red-600"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex justify-end mt-6">
                <button
                  type="submit"
                  class="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow disabled:cursor-wait disabled:opacity-75 text-button-font-primary bg-primary uppercase"
                >
                  {{ $t("Save") }}
                </button>
              </div>
            </form>
          </VeeForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import { mapActions } from "vuex";
import OnboardingService from "../../api/services/onboarding";
import * as yup from "yup";

const platforms = [
  { name: "Shopify", value: "shopify" },
  { name: "PrestaShop", value: "prestashop" },
  { name: "WooCommerce", value: "woo_commerce" },
  { name: "Magento", value: "magento" },
  { name: "Salesforce", value: "salesforce" },
  { name: "Other", value: "other" },
];

const storeTypes = [
  { name: "Electronics", value: "electronics" },
  { name: "Appliances", value: "appliances" },
  { name: "Furniture", value: "furniture" },
  {
    name: "Sports and fitness equipment",
    value: "sports_and_fitness_equipment",
  },
  { name: "Musical instruments", value: "musical_instruments" },
  { name: "Automotive parts", value: "automotive_parts" },
  { name: "Jewelry and watches", value: "jewelry_and_watches" },
  { name: "Eyewear", value: "eyewear" },
  { name: "Bikes", value: "bikes" },
  { name: "Other and multiple", value: "other_or_multiple" },
];

export default {
  components: { VeeForm, Field, ErrorMessage },

  data: () => {
    const schema = yup.object({
      storeType: yup.string().required("Please select a store type"),
      storeUrl: yup.string().required("Please enter your store URL"),
      platform: yup.string().required("Please select a platform"),
      annualSalesEstimate: yup
        .string()
        .required("Please give an estimate of your annual sales"),
      firstName: yup.string().required("Please enter your first name"),
      lastName: yup.string().required("Please enter your last name"),
      email: yup.string().required("Email is required."),
    });
    return {
      storeInfo: {
        storeType: "electronics",
        platform: "shopify",
      },
      schema,
      storeTypes,
      platforms,
      revenueEstimate: null,
    };
  },
  methods: {
    ...mapActions("notifications", ["notify"]),

    getStarted: async function () {
      try {
        const storeParams = {
          store_type: this.storeInfo.storeType,
          platform_cms: this.storeInfo.platform,
          annual_sales_estimate: parseInt(
            this.storeInfo.annualSalesEstimate.replace(/,/g, ""),
            10
          ),
          first_name: this.storeInfo.firstName,
          last_name: this.storeInfo.lastName,
          phone: this.storeInfo.phone,
          email: this.storeInfo.email,
        };

        await OnboardingService.getStarted({
          storeParams,
        });
        this.notify({
          category: "simple",
          type: "success",
        });
      } catch (error) {
        console.log(error.response);
        this.notify({
          category: "simple",
          type: "error",
        });
      }
    },

    handleGetStarted: async function () {
      await this.getStarted();
    },
  },
};
</script>
