<template>
  <div>
    <div class="mt-8 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div
          class="inline-block min-w-full py-2 pb-12 align-middle md:px-6 lg:px-8"
        >
          <div
            class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Flow
                  </th>
                  <th
                    scope="col"
                    class="py-3 pl-4 text-left text-sm font-semibold text-gray-900"
                  >
                    Date Début
                  </th>
                  <th
                    scope="col"
                    class="py-3 pl-4 text-left text-sm font-semibold text-gray-900"
                  >
                    Date Fin
                  </th>
                  <th
                    scope="col"
                    class="py-3 pl-4 text-left text-sm font-semibold text-gray-900"
                  >
                    Id
                  </th>
                  <th
                    scope="col"
                    class="py-3 pl-4 text-left text-sm font-semibold text-gray-900"
                  >
                    Montant
                  </th>
                  <th
                    scope="col"
                    class="py-3 pl-4 text-left text-sm font-semibold text-gray-900"
                  >
                    Entité
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Statut paiement
                  </th>
                  <th
                    scope="col"
                    class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Télécharger
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="invoice in this.invoices" :key="invoice.month">
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900"
                  >
                    <ArrowLeftIcon
                      v-if="invoice.invoiceFlow === 'to_estaly'"
                      class="text-red-500 h-5"
                    />
                    <ArrowRightIcon v-else class="text-green-500 h-5" />
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900"
                  >
                    {{ invoice.startDate }}
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900"
                  >
                    {{ invoice.endDate }}
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900"
                  >
                    {{ invoice.customId }}
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium"
                  >
                    <span
                      :class="
                        invoice.invoiceFlow === 'to_estaly'
                          ? 'text-red-600'
                          : 'text-green-600'
                      "
                    >
                      {{
                        (invoice.invoiceFlow === "to_estaly" ? "-" : "+") +
                        invoice.amount
                      }}
                      €
                    </span>
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900"
                  >
                    {{ invoice.salesChannel }}
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900"
                  >
                    <FieldSpan
                      v-bind:style-to-add="
                        getPaymentStatusFieldDisplayStyle(invoice.paymentStatus)
                      "
                      v-bind:value="invoice.paymentStatus"
                    />
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900"
                  >
                    <div class="flex">
                      <CloudDownloadIcon
                        class="h-6 w-6 text-primary-dark cursor-pointer"
                        aria-hidden="true"
                      />
                      <a
                        :href="invoice.fileUrl"
                        class="pl-2 text-base font-semibold text-primary-dark"
                        target="_blank"
                      >
                        {{ $t("Download") }}
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FieldSpan from "@/components/utils/FieldSpan.vue";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/vue/solid";
import { CloudDownloadIcon } from "@heroicons/vue/outline";

export default {
  components: {
    FieldSpan,
    ArrowRightIcon,
    ArrowLeftIcon,
    CloudDownloadIcon,
  },

  props: ["invoices"],

  methods: {
    getPaymentStatusFieldDisplayStyle: function (paymentStatus) {
      switch (paymentStatus) {
        case "pending_payment":
          return "bg-yellow-100 text-yellow-800";
        case "paid":
          return "bg-green-100 text-green-800";
        default:
          return "bg-blue-100 text-blue-800";
      }
    },
  },
};
</script>
