<template>
  <div class="mt-2 grid grid-cols-1 gap-1 place-items-center">
    <legend class="text-sm font-medium text-primary-dark">
      {{ $t("Uploaded files") }}
    </legend>
    <div
      class="border-t border-primary px-4 py-5 sm:p-0"
      v-for="(file, index) in files"
      v-bind:key="file"
    >
      <span class="ml-2 flex-1 text-sm w-0 truncate">{{ file.name }}</span>
      <button class="ml-3" @click="removeFile(index)">
        <TrashIcon class="h-5 w-5" aria-hidden="true" />
      </button>
    </div>
  </div>
</template>

<script>
import { TrashIcon } from "@heroicons/vue/solid";
export default {
  components: {
    TrashIcon,
  },
  props: ["files"],
  methods: {
    removeFile: function (index) {
      this.$emit("removeFile", index);
    },
  },
};
</script>
