<template>
  <div v-if="this.voucher" class="bg-gray shadow overflow-hidden sm:rounded-lg">
    <div class="px-4 py-5 sm:px-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        {{ $t("Voucher Information") }}
      </h3>
    </div>
    <div class="border-t border-gray-200">
      <dl>
        <div
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6 border-b border-gray-200"
        >
          <dt class="text-sm font-medium text-gray-500">Titre</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
            <p>{{ voucher.title }}</p>
          </dd>
        </div>
        <div
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6 border-b border-gray-200"
        >
          <dt class="text-sm font-medium text-gray-500">Description</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
            <p>{{ voucher.description }}</p>
          </dd>
        </div>
        <div
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6 border-b border-gray-200"
        >
          <dt class="text-sm font-medium text-gray-500">Reference ID</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
            <p>{{ voucher.referenceId }}</p>
          </dd>
        </div>
        <div
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6 border-b border-gray-200"
        >
          <dt class="text-sm font-medium text-gray-500">Amount</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
            <p>{{ voucher.amount }}</p>
          </dd>
        </div>
        <div
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6 border-b border-gray-200"
        >
          <dt class="text-sm font-medium text-gray-500">Date d'utilisation</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
            {{ voucher.usageDate }}
          </dd>
        </div>
        <div
          class="bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-6 border-b border-gray-200"
        >
          <dt class="text-sm font-medium text-gray-500">Utilisable jusqu'à</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
            {{ voucher.endValidityDate }}
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  props: ["voucher"],
};
</script>
