import AuthService from "../../api/services/auth";
import UserService from "../../api/services/user";
import { isAdmin, isSeller } from "@/utils/user_roles";

export default {
  namespaced: true,
  state: {
    currentUser: {},
    signedIn: false,
    csrf: null,
  },

  getters: {
    isStore: (state) =>
      isAdmin(state.currentUser) || state.currentUser.role === "storemanager",
    isStoreOwner: (state) => state.currentUser.role === "storemanager",
    isAdmin: (state) => isAdmin(state.currentUser),
    isCustomer: (state) => state.currentUser.role === "customer",
    isOffline: (state) => state.currentUser.storeSource === "offline",
    isOnline: (state) => state.currentUser.storeSource === "online",
    isSeller: (state) => isSeller(state.currentUser),

    currentUserHasPermission: (state) => (permission) => {
      return state.currentUser
        ? state.currentUser.permissions.includes(permission)
        : false;
    },

    currentUserCanRecordASale: (state) => {
      return state.currentUser?.recordASalePermission === true;
    },
  },

  mutations: {
    setCurrentUser(state, { currentUser, csrf }) {
      state.currentUser = currentUser;
      state.signedIn = true;
      state.csrf = csrf;
    },

    unsetCurrentUser(state) {
      state.currentUser = {};
      state.signedIn = false;
      state.csrf = null;
    },

    refresh(state, csrf) {
      state.csrf = csrf;
      state.signedIn = true;
    },
  },

  actions: {
    async signin({ commit }, user) {
      try {
        const response = await AuthService.signin(user);
        if (response.data.csrf) {
          const userResponse = await UserService.getUser();
          const csrf = response.data.csrf;
          const currentUser = userResponse.data;
          commit("setCurrentUser", { currentUser, csrf });
        } else {
          commit("unsetCurrentUser");
        }
      } catch (error) {
        return Promise.reject(error);
      }
    },

    async refreshUser({ commit, state }) {
      try {
        const response = await UserService.getUser();
        const currentUser = response.data;
        const csrf = state.csrf;
        commit("setCurrentUser", { currentUser, csrf });
      } catch (error) {
        console.log(error);
      }
    },

    async signout({ commit }) {
      try {
        await AuthService.signout();
        commit("unsetCurrentUser");
        localStorage.clear();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
