import { securedAxiosInstance } from "../axios";

import { ADMIN_PLAN_SALES_URL } from "./constants";
import { toSnakeCase } from "@/utils/utils.js";

class AdminService {
  getPlanSales(params) {
    return securedAxiosInstance.post(ADMIN_PLAN_SALES_URL, params);
  }
  getClaims({ data }) {
    return securedAxiosInstance.get(`/admin/claims`, {
      params: {
        stores: data.stores,
        status: data.status,
        page: data.page,
        keywords: data.keywords,
      },
    });
  }

  getClaimByFriendlyId(friendlyId) {
    return securedAxiosInstance.get(`/admin/claims/${friendlyId}`);
  }

  getInvoices(page, filtering_data) {
    return securedAxiosInstance.post(
      `/admin/invoices?page=${page}`,
      filtering_data
    );
  }

  addQuotationToClaim(claimFriendlyId, formData, repairCost) {
    return securedAxiosInstance.post(
      `/admin/claims/quotations?claim_id=${claimFriendlyId}&repair_cost=${repairCost}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  addInvoiceToClaim(claimFriendlyId, formData, repairCost) {
    return securedAxiosInstance.post(
      `/admin/claims/invoices?claim_id=${claimFriendlyId}&repair_cost=${repairCost}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  getPlanSaleCSVExport(filterParams) {
    return securedAxiosInstance.get("/admin/plan_sales/reporting", {
      params: toSnakeCase(filterParams),
    });
  }
}

export default new AdminService();
