<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <HeadlessUIDialog as="div" class="relative z-30">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full lg:ml-20 max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all relative"
            >
              <div class="absolute top-4 right-4">
                <XIcon
                  class="w-6 h-6 cursor-pointer"
                  @click="closeSellerModal"
                />
              </div>
              <VeeForm
                v-slot="{ handleSubmit }"
                :validation-schema="schema"
                as="div"
              >
                <form
                  class="space-y-4"
                  @submit="handleSubmit($event, handleSellerCreation)"
                  ref="saleCreationForm"
                >
                  <div class="grid grid-cols-2 gap-x-4 gap-y-3">
                    <div class="flex-1">
                      <label
                        for="firstName"
                        class="block text-sm text-gray-700"
                      >
                        Prénom
                        <span class="ml-1 text-red-600">*</span>
                      </label>
                      <Field
                        type="text"
                        name="seller.firstName"
                        id="firstName"
                        autocomplete="given-name"
                        class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="John"
                        v-model="seller.firstName"
                      />
                      <ErrorMessage
                        name="seller.firstName"
                        class="mt-2 text-sm text-red-600"
                      />
                    </div>
                    <div class="flex-1">
                      <label for="lastName" class="block text-sm text-gray-700">
                        Nom
                        <span class="ml-1 text-red-600">*</span>
                      </label>
                      <Field
                        type="text"
                        name="seller.lastName"
                        id="lastName"
                        autocomplete="family-name"
                        class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="Doe"
                        v-model="seller.lastName"
                      />
                      <ErrorMessage
                        name="seller.lastName"
                        class="mt-2 text-sm text-red-600"
                      />
                    </div>
                    <div class="flex-1">
                      <label for="email" class="block text-sm text-gray-700">
                        {{ $t("Email")
                        }}<span class="ml-1 text-red-600">*</span>
                      </label>
                      <div class="mt-1">
                        <Field
                          id="email"
                          name="seller.email"
                          type="email"
                          class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                          placeholder="seller@gmail.com"
                          v-model="seller.email"
                        />
                        <ErrorMessage
                          name="seller.email"
                          class="mt-2 text-sm text-red-600"
                        />
                      </div>
                    </div>
                    <div class="flex-1">
                      <label for="phone" class="block text-sm text-gray-700">
                        {{ $t("Phone number")
                        }}<span class="ml-1 text-red-600">*</span>
                      </label>
                      <div class="mt-1">
                        <Field
                          id="phone"
                          name="seller.phone"
                          type="tel"
                          class="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                          placeholder="+33 6 99 49 75 90"
                          v-model="seller.phone"
                        />
                        <ErrorMessage
                          name="seller.phone"
                          class="mt-2 text-sm text-red-600"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="flex justify-center">
                    <div>
                      <button
                        class="border border-transparent text-button-font-primary bg-primary px-4 py-2 text-sm font-medium uppercase transition duration-300 transform hover:scale-105 rounded-lg focus:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2"
                        type="submit"
                      >
                        {{ $t("Add") }}
                      </button>
                    </div>
                  </div>
                </form>
              </VeeForm>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </HeadlessUIDialog>
  </TransitionRoot>
</template>

<script>
import StoreService from "@/api/services/store";
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import {
  Dialog as HeadlessUIDialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";
import * as yup from "yup";
import { mapActions } from "vuex";

export default {
  components: {
    VeeForm,
    Field,
    ErrorMessage,
    HeadlessUIDialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },

  props: {
    isOpen: Boolean,
  },

  emits: ["getSellers", "closeSellerCreation"],

  data: () => {
    const phoneRegex =
      /^(?:(\+33|0033)\s*[67]\s*(\d\s*){8}|0[67](\d\s*){8}|(\+590|00590)\s*(\d\s*){9}|(\+596|00596)\s*(\d\s*){9}|(\+594|00594)\s*(\d\s*){9}|(\+262|00262)\s*(\d\s*){9})$/;

    const schema = yup.object({
      seller: yup.object({
        email: yup.string().required("L'email est requis."),
        phone: yup
          .string()
          .required("Le numéro de téléphone est requis.")
          .matches(
            phoneRegex,
            "Le numéro de téléphone n'est pas valide pour la France ou les départements d'outre-mer."
          ),
        firstName: yup.string().required("Le prénom du vendeur est requis."),
        lastName: yup.string().required("Le nom du vendeur est requis."),
      }),
    });

    return {
      schema,
      seller: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
      },
    };
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    closeSellerModal: function () {
      this.$emit("closeSellerCreation");
    },

    resetAttributes: function () {
      this.seller = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
      };
    },

    handleSellerCreation: async function () {
      try {
        const storeId = this.$store.state.store.selectedStoreId;
        const sellerData = {
          seller: {
            first_name: this.seller.firstName,
            last_name: this.seller.lastName,
            email: this.seller.email,
            phone: this.seller.phone,
          },
        };
        await StoreService.createSeller(storeId, sellerData);
        this.$emit("getSellers");
        this.closeSellerModal();
        this.notify({
          category: "simple",
          type: "success",
          title: "Le vendeur a été ajouté avec succès.",
        });
        setTimeout(() => {
          this.resetAttributes();
        }, 500);
      } catch (error) {
        const statusCode = error.response.status;
        switch (statusCode) {
          case 409:
            this.notify({
              type: "error",
              category: "simple",
              title: "Seller already exists.",
              text: "Please select it.",
            });
            this.closeSellerModal();
            break;
          default:
            this.notify({
              type: "error",
              category: "simple",
            });
        }
      }
    },
  },
};
</script>
