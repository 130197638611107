<template>
  <Modal v-bind:open="this.open" @close="this.close">
    <div
      class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-primary"
    >
      <DocumentReportIcon class="h-6 w-6 text-primary-dark" aria-hidden="true" />
    </div>

    <FileUploadArea
      v-bind:question="this.$t('Upload an invoice')"
      v-bind:multiple="false"
      v-bind:send-button-text="this.$t('Add Invoice')"
      @sendFiles="this.sendinvoiceFile"
    >
      <div class="pt-4 pb-4 text-sm text-gray-900">
        <p class="font-semibold">{{ $t("invoice_explanation_1") }}</p>
        <p>
          {{ $t("invoice_explanation_2") }}
        </p>
      </div>

      <div class="pb-4">
        <label
          for="price"
          class="block text-sm font-medium leading-6 text-gray-900"
          >Montant des réparations</label
        >
        <div class="relative mt-2 rounded-md shadow-sm">
          <div
            class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
          >
            <span class="text-gray-500 sm:text-sm">$</span>
          </div>
          <input
            type="text"
            name="price"
            id="price"
            class="block w-full rounded-md border-0 py-1.5 pl-7 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
            placeholder="0.00"
            aria-describedby="price-currency"
            v-model="repairCost"
          />
          <div
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
          >
            <span class="text-gray-500 sm:text-sm" id="price-currency"
              >EUR</span
            >
          </div>
        </div>
      </div>
    </FileUploadArea>
  </Modal>
</template>

<script>
import { DocumentReportIcon } from "@heroicons/vue/outline";
import FileUploadArea from "@/components/upload/FileUploadArea.vue";
import Modal from "@/components/modal/Modal.vue";
import { isStringValid } from "@/utils/validation";
import { mapActions } from "vuex";

export default {
  components: {
    DocumentReportIcon,
    FileUploadArea,
    Modal,
  },
  props: ["open"],
  data() {
    return {
      repairCost: "",
    };
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    close: function () {
      this.$emit("close");
    },
    sendinvoiceFile: function (file) {
      if (isStringValid(this.repairCost)) {
        this.$emit("invoiceFileUpload", file, this.repairCost);
      } else {
        this.notify({
          category: "simple",
          type: "error",
          title: "Montant des réparations",
          text: "Veuillez préciser le montant des réparations",
        });
      }
    },
  },
};
</script>
