<template>
  <div>
    <Tooltip v-bind:name="this.product.title">
      <template v-slot:content>
        <UnapprovedProductInformationModal
          v-bind:open="this.open"
          @close="this.closeModal"
        >
          <template v-slot:title>{{
            $t("Unapproved product alert title")
          }}</template>
          <template v-slot:text
            >{{ $t("Unapproved product alert content") }}
          </template>
        </UnapprovedProductInformationModal>
      </template>
      <template v-slot:trigger>
        <button @click="this.openModal">
          <XCircleIcon class="h-5 w-5 text-red-600" aria-hidden="true" />
        </button>
      </template>
    </Tooltip>
  </div>
</template>

<script>
import { XCircleIcon } from "@heroicons/vue/solid";
import Tooltip from "@/components/tooltip/Tooltip.vue";
import UnapprovedProductInformationModal from "@/views/store/products/list/content/utils/UnapprovedProductInformationModal.vue";
export default {
  components: {
    Tooltip,
    XCircleIcon,
    UnapprovedProductInformationModal,
  },
  data: () => {
    return {
      open: false,
    };
  },

  props: ["product"],

  methods: {
    openModal: function () {
      this.open = true;
    },
    closeModal: function () {
      this.open = false;
    },
  },
};
</script>
