<template>
  <Listbox as="div" v-model="selected" class="flex items-center">
    <ListboxLabel class="block text-sm font-medium text-white"></ListboxLabel>
    <div class="relative">
      <ListboxButton
        class="rounded-lg relative cursor-pointer border bg-white py-2 pl-3 text-left focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm w-52"
      >
        <div class="flex flex-row items-center">
          <UserCircleIcon class="h-6 w-6 text-primary-dark" />
          <div class="ml-2 block truncate">
            <span
              class="block truncate font-medium text-primary-dark"
              style="font-size: 0.5rem"
            >
              Compte actif
            </span>
            <span
              class="block truncate font-medium text-xs pr-6 text-primary-dark"
              >{{ selected.salesChannel }}</span
            >
            <span
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-1"
            >
              <ChevronDownIcon
                class="h-5 w-5"
                style="font-size: 0.5rem"
                aria-hidden="true"
              />
            </span>
          </div>
        </div>
      </ListboxButton>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm cursor-pointer"
        >
          <ListboxOption
            as="template"
            v-for="store in stores"
            :key="store.id"
            :value="store"
            v-slot="{ active, selected }"
          >
            <li
              :class="[
                active ? 'text-primary-dark bg-primary-light' : 'text-primary-dark',
                'relative cursor-default select-none py-2 pl-3 pr-9 cursor-pointer',
              ]"
            >
              <span
                :class="[
                  selected ? 'font-semibold' : 'font-normal',
                  'text-xs block truncate cursor-pointer',
                ]"
                >{{ store.salesChannel }}</span
              >

              <span
                v-if="selected"
                :class="[
                  active ? 'text-primary-dark' : 'text-primary-dark',
                  'absolute inset-y-0 right-0 flex items-center pr-4 cursor-pointer',
                ]"
              >
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import StoreService from "@/api/services/store";
import { ref } from "vue";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import {
  CheckIcon,
  ChevronDownIcon,
  UserCircleIcon,
} from "@heroicons/vue/solid";
import { mapActions, mapState } from "vuex";
import { isAdmin } from "@/utils/user_roles";
import { isObjectValid, isStringValid } from "@/utils/validation";

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    ChevronDownIcon,
    UserCircleIcon,
  },

  props: {
    store_id: Number,
  },

  data() {
    return {
      stores: [],
      currentPage: 1,
      selected: { id: 0, salesChannel: "Admin" },
      general: [{ id: 0, salesChannel: "Admin" }],
    };
  },
  watch: {
    selected(newValue, oldValue) {
      if (isObjectValid(newValue)) {
        if (newValue.salesChannel === "Admin") {
          this.updateStoreId(newValue.id);
          if (isStringValid(oldValue) && newValue !== oldValue) {
            this.$router.push("/admin");
          }
        } else if (newValue.source === "offline") {
          this.updateStoreId(newValue.id);
          if (isStringValid(oldValue) && newValue !== oldValue) {
            this.$router.push(`/pos`);
          }
        } else {
          this.updateStoreId(newValue.id);
          if (isStringValid(oldValue) && newValue !== oldValue) {
            this.$router.push(`/platform/products`);
          }
        }
      } else {
        this.updateStoreId("");
      }
    },
  },

  computed: {
    isAdmin: function () {
      return isAdmin(this.currentUser);
    },
  },

  methods: {
    ...mapActions("store", ["updateStoreId"]),
    ...mapState("auth", ["currentUser"]),

    getStores: async function () {
      try {
        const response = await StoreService.getStores({ status: "active" });
        this.stores = this.general.concat(response.data.stores);
        if (this.$store.state.store.selectedStoreId) {
          this.selected = ref(
            this.stores.find(
              (x) => x.id === this.$store.state.store.selectedStoreId
            )
          );
        } else {
          this.selected = ref(this.stores[0]);
        }
      } catch (error) {
        console.log(error.response);
        this.notify({
          category: "simple",
          type: "error",
        });
      }
    },
  },

  beforeMount() {
    this.getStores();
  },
};
</script>
