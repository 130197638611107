<template>
  <tbody class="divide-y divide-gray-200">
    <tr
      v-for="item in averageAndTotalStats"
      :key="item.name"
      :class="`${
        cursorPointerDisplayEnabled(item)
          ? 'bg-white hover:bg-gray-50'
          : isStringValid(cursorPointer)
          ? 'bg-gray-100'
          : 'bg-white'
      } grid ${gridCols} ${
        cursorPointerDisplayEnabled(item) ? cursorPointer : ''
      }`"
      @click="clickedRowEventTriggered(item)"
    >
      <td
        class="break-words flex items-center col-span-1 py-2 pl-2 text-xs font-medium text-gray-900 sticky left-0 bg-white hover:bg-gray-50"
        style="border-bottom: 0.25px solid #ccc"
      >
        {{ item.name }}
      </td>
      <td
        class="flex items-center col-span-1 px-2 py-2 text-xs text-gray-500 pl-5"
        style="border-bottom: 0.25px solid #ccc"
      >
        {{ item.sales_count }}
      </td>
      <td
        class="flex items-center col-span-1 px-2 py-2 text-xs text-gray-500"
        style="border-bottom: 0.25px solid #ccc"
        v-if="isStringValid(item.sales_count_target)"
      >
        {{ item.sales_count_target }}
      </td>
      <td
        class="flex items-center col-span-1 px-2 py-2 text-xs text-gray-500"
        style="border-bottom: 0.25px solid #ccc"
        v-if="isStringValid(item.sales_count_result_target)"
      >
        <div
          class="inline-block px-1.5 py-1 rounded-xl"
          :class="{
            'bg-green-100 text-green-500 border border-green-300':
              item.sales_count_result_target > 80,
            'bg-yellow-100 text-yellow-500 border border-yellow-300':
              item.sales_count_result_target >= 50,
            'bg-red-100 text-red-500 border border-red-300':
              item.sales_count_result_target < 50,
          }"
        >
          {{ item.sales_count_result_target }} %
        </div>
      </td>
      <td
        class="flex items-center col-span-1 px-2 py-2 text-xs text-gray-500"
        style="border-bottom: 0.25px solid #ccc; border-left: 4px solid #ccc"
      >
        {{ item.sales_count_projection }}
      </td>
      <td
        class="flex items-center col-span-1 px-2 py-2 text-xs text-gray-500"
        style="border-bottom: 0.25px solid #ccc"
        v-if="isStringValid(item.sales_count_projection_target)"
      >
        <div
          class="inline-block px-2 py-1 rounded-xl"
          :class="{
            'bg-green-100 text-green-500 border border-dashed border-green-300 ':
              item.sales_count_projection_target > 80,
            'bg-yellow-100 text-yellow-500 border border-dashed border-yellow-300':
              item.sales_count_projection_target >= 50,
            'bg-red-100 text-red-500 border border-dashed border-red-300':
              item.sales_count_projection_target < 50,
          }"
        >
          {{ item.sales_count_projection_target }} %
        </div>
      </td>
      <td
        class="flex items-center col-span-1 px-2 py-2 text-xs text-gray-500"
        style="border-bottom: 0.25px solid #ccc; border-right: 4px solid #ccc"
      >
        <div
          class="inline-block px-2 py-1 rounded-xl"
          :class="{
            'bg-green-100 text-green-500 border border-dashed border-green-300':
              this.formatVariation(item.sales_count_projection_variation) > 0 ||
              this.formatVariation(item.sales_count_projection_variation) ===
                '+ ∞',
            'bg-red-100 text-red-500 border border-dashed border-red-300':
              this.formatVariation(item.sales_count_projection_variation) < 0,
            'bg-gray-100 text-gray-500 border border-dashed border-gray-300':
              this.formatVariation(item.sales_count_projection_variation) === 0,
          }"
        >
          {{ this.formatVariation(item.sales_count_projection_variation) }}
          %
        </div>
      </td>
      <td
        class="flex items-center col-span-1 px-2 py-2 text-xs text-gray-500"
        style="border-bottom: 0.25px solid #ccc"
      >
        {{ item.lead_count }}
      </td>
      <td
        class="flex items-center col-span-1 px-2 py-2 text-xs text-gray-500"
        style="border-bottom: 0.25px solid #ccc"
      >
        {{ item.lead_conversion_rate }} %
      </td>
      <td
        class="flex items-center col-span-1 px-2 py-2 text-xs text-gray-500"
        style="border-bottom: 0.25px solid #ccc"
      >
        {{ item.cancellation_rate }} %
      </td>
      <td
        class="flex items-center col-span-1 px-2 py-2 text-xs text-gray-500"
        style="border-bottom: 0.25px solid #ccc"
      >
        {{ item.monthly_sales_rate }} %
      </td>
    </tr>
  </tbody>
</template>

<script>
import { formatVariation } from "@/utils/variation_formatter";
import { isStringValid } from "@/utils/validation";

export default {
  name: "StatsTable",
  props: {
    averageAndTotalStats: {
      type: Array,
      required: true,
    },
    gridCols: {
      type: String,
      default: "grid-cols-8",
    },
    cursorPointer: {
      type: String,
      default: "",
    },
    onRowClick: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    formatVariation,
    isStringValid,

    cursorPointerDisplayEnabled: function (item) {
      return (
        isStringValid(this.cursorPointer) && item.seller_data_available === true
      );
    },

    clickedRowEventTriggered: function (item) {
      if (this.cursorPointerDisplayEnabled(item)) {
        this.onRowClick(item.id);
      }
    },
  },
};
</script>
