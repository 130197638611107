import { plainAxiosInstance, securedAxiosInstance } from "../axios";

import { GETSTARTED_URL } from "./constants";

class OnboardingService {
  createOnboarding({ storeId, storeParams, userParams, businessParams }) {
    const data = {
      store: storeParams,
      user: userParams,
      business: businessParams,
    };

    return securedAxiosInstance.post(`/store/${storeId}/onboarding`, data);
  }

  createRevenueEstimate(storeId, annualSalesEstimate) {
    const data = {
      annual_sales_estimate: annualSalesEstimate,
    };

    return securedAxiosInstance.post(
      `/store/${storeId}/revenue_estimate`,
      data
    );
  }

  getStarted({ storeParams }) {
    const data = {
      store: storeParams,
    };

    return plainAxiosInstance.post(GETSTARTED_URL, data);
  }
}

export default new OnboardingService();
