<template>
  <div class="fixed top-0 left-0 right-0 z-50 flex items-center justify-between gap-x-6 bg-secondary px-6 py-2.5 sm:pr-3.5 lg:pl-8">
    <p class="text-sm font-body-bold leading-6 text-black">
      <a href="#" @click.prevent="redirectToStores">Informations d'un magasin à compléter&nbsp;<span aria-hidden="true">&rarr;</span>
      </a>
    </p>
  </div>
</template>

<script>
export default {
  methods: {
    redirectToStores() {
      this.$router.push({ path: '/admin/stores' });
    }
  }
};
</script>
