<template>
  <div>
    <div>
      <div class="flex flex-row items-center justify-between">
        <div>
          <div>
            <p>Veuillez télécharger le devis de gestion du sinistre.</p>
          </div>
        </div>
        <button
          class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-button-font-primary bg-primary transition duration-300 transform hover:scale-105 rounded-lg focus:outline-none focus:ring-primary"
          @click="this.openModal = true"
        >
          {{ $t("Upload a quotation") }}
        </button>
      </div>
    </div>
    <div class="shadow overflow-hidden sm:rounded-lg text-center">
      <QuotationUploadModal
        v-bind:open="this.openModal"
        @close="this.openModal = false"
        @quotationFileUpload="this.sendQuotationFile"
      />
    </div>
  </div>
</template>

<script>
import QuotationUploadModal from "@/components/claim/details/quotation/QuotationUploadModal.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    QuotationUploadModal,
  },
  props: ["adminView"],
  data() {
    return {
      openModal: false,
    };
  },
  methods: {
    ...mapActions("claims", [
      "uploadStoreQuotationFile",
      "uploadAdminQuotationFile",
    ]),
    ...mapGetters("store", ["selectedStoreId"]),
    ...mapGetters("claims", ["getClaimFriendlyId"]),
    ...mapActions("notifications", ["notify"]),

    sendQuotationFile: async function (file, repairCost) {
      try {
        if (this.adminView) {
          await this.uploadAdminQuotationFile({
            claimFriendlyId: this.getClaimFriendlyId(),
            file: file,
            repairCost: repairCost,
          });
        } else {
          await this.uploadStoreQuotationFile({
            claimFriendlyId: this.getClaimFriendlyId(),
            storeId: this.selectedStoreId(),
            file: file,
            repairCost: repairCost,
          });
        }
      } catch (error) {
        await this.notify({
          category: "simple",
          type: "error",
        });
      }

      this.openModal = false;
    },
  },
};
</script>
