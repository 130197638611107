<template>
  <h1 class="text-4xl font-heading-1 pl-2 tracking-tight text-gray-900 my-4">
    {{ $t("Stores") }}
  </h1>
  <div v-if="store">
    <StoreSetup v-if="store.status === 'setup'" :store="store" @configurationUpdated="fetchStoreDetails"/>
    <StoreActive v-else :store="store" />
  </div>
  <div v-else>
    <p>{{ $t('Loading...') }}</p>
  </div>
</template>

<script>
import StoreService from "@/api/services/store";
import StoreActive from "@/views/admin/store/details/StoreActive.vue";
import StoreSetup from "@/views/admin/store/details/StoreSetup.vue";
import { mapActions } from "vuex";

export default {
  components: {
    StoreActive,
    StoreSetup,
  },
  data() {
    return {
      store: null,
    };
  },
  computed: {
    isOfflineStore() {
      return this.store?.source === "Offline";
    },
  },
  async mounted() {
    await this.fetchStoreDetails();
  },
  methods: {
    ...mapActions("notifications", ["notify"]),
    ...mapActions("auth", ["refreshUser"]),
    async fetchStoreDetails() {
      const storeId = this.$route.params.id;
      try {
        const response = await StoreService.getStore(storeId);
        this.store = response.data;
      } catch (error) {
        await this.notify({
          category: "simple",
          type: "error",
        });
        console.error("Error while retrieving the store :", error);
      }
    },
  },
  watch: {
    store(newStore, oldStore) {
      if (oldStore?.setupStatus == "information_to_be_completed" && newStore?.setupStatus == "pending_verification") {
        this.notify({
          category: "simple",
          type: "success",
          title: "Point de vente configuré !",
          text: "Merci d'avoir complété les informations de votre point de vente. Votre demande est en cours de vérification.",
        });
        this.refreshUser(); // update currentUser.storeToSetup (update Banner to setup store display)
      }
    },
  },
};
</script>
