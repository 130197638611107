<template>
  <div>
    <div class="flex justify-between items-center">
      <DailyDateFilterSelector
        class=""
        placeholder="Sélectionner une période"
        @newDateSelected="this.handleNewDateSelected"
      />
      <button
        class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-button-font-primary bg-primary transition duration-300 transform hover:scale-105 rounded-lg focus:outline-none"
        @click="this.emitCSVExport"
        v-if="this.isAdmin && this.isAdminPage"
      >
        {{ $t("Export") }}
      </button>
    </div>
    <SearchBar
      class="my-6"
      v-bind:placeholder="$t('SearchPlanSales')"
      v-bind:current-page="this.currentPage"
      v-bind:available-filters="this.availableFilters"
      v-bind:selected-filters="this.selectedFilters"
      v-bind:initial-keywords="this.keywords"
      @updateFilterSearch="this.updateFilterSearch"
      @updateKeywordSearch="this.updateKeywordSearch"
    />
  </div>
</template>

<script>
import DailyDateFilterSelector from "@/components/menu/DailyDateFilterSelector.vue";
import SearchBar from "@/components/menu/SearchBar.vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  components: { SearchBar, DailyDateFilterSelector },

  props: ["currentPage"],

  computed: {
    ...mapState("plan_sale_list", [
      "availableFilters",
      "selectedFilters",
      "keywords",
    ]),
    ...mapGetters("auth", ["isAdmin"]),
    ...mapGetters("navigation", ["isAdminPage"]),
  },

  methods: {
    ...mapActions("plan_sale_list", [
      "updateFilter",
      "updateSearch",
      "updateDateFilter",
    ]),

    updateKeywordSearch: function (search) {
      const newPage = 1;
      this.updatePage(newPage);
      this.updateSearch({ searchString: search, page: newPage });
    },

    updateFilterSearch: function (filterName, filterOptions) {
      const newPage = 1;
      this.updatePage(newPage);
      this.updateFilter({
        filterName: filterName,
        filterOptions: filterOptions,
        page: newPage,
      });
    },

    handleNewDateSelected: function (startDate, endDate) {
      const newPage = 1;
      this.updatePage(newPage);
      this.updateDateFilter({
        startDate: startDate,
        endDate: endDate,
        page: newPage,
      });
    },

    updatePage: function (newPage) {
      this.$emit("updatePage", newPage, false);
    },

    emitCSVExport: function () {
      this.$emit("exportCSV");
    },
  },
};
</script>
