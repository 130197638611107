<template>
  <div>
    <div class="bg-white p-4 rounded-md mt-4">
      <p class="text-xl font-heading-1 pb-4 tracking-tight text-gray-900">
        Souscription
      </p>
      <div>
        <button
          type="submit"
          class="w-full py-2 px-4 border border-transparent shadow-sm text-lg font-medium text-button-font-secondary bg-secondary transition duration-300 transform hover:scale-105 rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
          v-on:click="this.paymentInScreen"
        >
          <div class="flex align-center justify-center">
            <div class="flex justify-center flex-col">
              <DesktopComputerIcon class="h-5 w-5 mr-2" />
            </div>
            <p class="uppercase font-body-bold">Payer sur cet écran</p>
          </div>
        </button>
        <button
          type="submit"
          class="mt-2 w-full justify-center py-2 px-4 border border-transparent shadow-sm text-lg font-medium text-button-font-primary bg-primary transition duration-300 transform hover:scale-105 rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          v-on:click="this.sendTextMessage"
        >
          <div class="flex align-center justify-center">
            <div class="flex justify-center flex-col">
              <DeviceMobileIcon class="h-5 w-5 mr-2" />
            </div>
            <p class="uppercase font-body">Envoyer un lien par SMS</p>
          </div>
        </button>
        <button
          v-if="this.currentUserCanRecordASale"
          type="submit"
          class="mt-2 w-full justify-center py-2 px-4 border border-transparent shadow-sm text-lg font-medium text-button-font-primary bg-primary transition duration-300 transform hover:scale-105 rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          v-on:click="this.recordASale"
        >
          <div class="flex align-center justify-center">
            <div class="flex justify-center flex-col">
              <LightningBoltIcon class="h-5 w-5 mr-2" />
            </div>
            <p class="uppercase font-body">{{ $t("Record the sale") }}</p>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DeviceMobileIcon,
  DesktopComputerIcon,
  LightningBoltIcon,
} from "@heroicons/vue/outline";
import { mapGetters } from "vuex";

export default {
  components: {
    DeviceMobileIcon,
    DesktopComputerIcon,
    LightningBoltIcon,
  },

  computed: {
    ...mapGetters("auth", ["currentUserCanRecordASale"]),
  },

  methods: {
    sendTextMessage: function () {
      this.$emit("sendTextMessage");
    },
    paymentInScreen: function () {
      this.$emit("paymentInScreen");
    },
    recordASale: function () {
      this.$emit("recordASale");
    },
  },
};
</script>
