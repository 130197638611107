<template>
  <ProductDescription v-bind:product="product" />
  <VariantList
    class="text-center mt-4"
    v-bind:product-id="product.id"
    v-bind:variants="product.variants"
  />
  <div class="text-center mt-8">
    <GenericButton @onClick="this.redirectToPreviousPage">
      {{ $t("Back") }}
    </GenericButton>
  </div>
</template>

<script>
import GenericButton from "@/components/utils/GenericButton.vue";
import VariantList from "@/views/store/products/details/variant/VariantList.vue";
import ProductDescription from "@/views/store/products/details/description/ProductDescription.vue";
import StoreService from "@/api/services/store";

export default {
  components: {
    VariantList,
    ProductDescription,
    GenericButton,
  },
  data: () => {
    return {
      product: {},
    };
  },
  methods: {
    getProduct: async function () {
      const productId = this.$route.params.id;
      const response = await StoreService.getProductById(
        this.$store.state.store.selectedStoreId,
        productId
      );
      this.product = response.data;
    },
    redirectToPreviousPage: function () {
      this.$router.push("/platform/products");
    },
  },
  created() {
    this.getProduct();
  },
};
</script>
