import { isObjectValid } from "@/utils/validation";
import StoreService from "@/api/services/store";
import AdminService from "@/api/services/admin";

const initialState = {
  currentClaim: null,
};

export default {
  namespaced: true,

  state: initialState,

  getters: {
    getClaimFriendlyId: (state) => {
      return isObjectValid(state.currentClaim)
        ? state.currentClaim.friendlyId
        : null;
    },
    doesClaimExists: (state) => {
      return isObjectValid(state.currentClaim);
    },
    answers: (state) => {
      return isObjectValid(state.currentClaim)
        ? JSON.parse(JSON.stringify(state.currentClaim.answers))
        : null;
    },
  },

  mutations: {
    setCurrentClaim(state, currentClaim) {
      state.currentClaim = currentClaim;
    },
  },

  actions: {
    getStoreClaim: async function ({ commit }, { storeId, claimFriendlyId }) {
      try {
        const response = await StoreService.getClaimByFriendlyId(
          storeId,
          claimFriendlyId
        );
        commit("setCurrentClaim", response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    reset: function ({ commit }) {
      commit("setCurrentClaim", null);
    },

    uploadStoreQuotationFile: async function (
      { dispatch },
      { claimFriendlyId, storeId, file, repairCost }
    ) {
      try {
        let formData = new FormData();
        formData.append("quotation_file", file);
        await StoreService.addQuotationToClaim(
          storeId,
          claimFriendlyId,
          formData,
          repairCost
        );
        dispatch("getStoreClaim", { storeId, claimFriendlyId });
      } catch (error) {
        return Promise.reject(error);
      }
    },

    uploadStoreInvoiceFile: async function (
      { dispatch },
      { claimFriendlyId, storeId, file, repairCost }
    ) {
      try {
        let formData = new FormData();
        formData.append("invoice_file", file);
        await StoreService.addInvoiceToClaim(
          storeId,
          claimFriendlyId,
          formData,
          repairCost
        );
        dispatch("getStoreClaim", { storeId, claimFriendlyId });
      } catch (error) {
        return Promise.reject(error);
      }
    },

    getAdminClaim: async function ({ commit }, { claimFriendlyId }) {
      try {
        const response = await AdminService.getClaimByFriendlyId(
          claimFriendlyId
        );
        commit("setCurrentClaim", response.data);
      } catch (error) {
        return Promise.reject(error);
      }
    },

    uploadAdminQuotationFile: async function (
      { dispatch },
      { claimFriendlyId, file, repairCost }
    ) {
      try {
        let formData = new FormData();
        formData.append("quotation_file", file);
        await AdminService.addQuotationToClaim(
          claimFriendlyId,
          formData,
          repairCost
        );
        dispatch("getAdminClaim", { claimFriendlyId });
      } catch (error) {
        return Promise.reject(error);
      }
    },

    uploadAdminInvoiceFile: async function (
      { dispatch },
      { claimFriendlyId, file, repairCost }
    ) {
      try {
        let formData = new FormData();
        formData.append("invoice_file", file);
        await AdminService.addInvoiceToClaim(
          claimFriendlyId,
          formData,
          repairCost
        );
        dispatch("getAdminClaim", { claimFriendlyId });
      } catch (error) {
        return Promise.reject(error);
      }
    },
  },
};
