<template>
  <h2 class="pl-2">
    Compléter les informations sur votre magasin pour commencer à proposer
    Estaly à vos clients.
  </h2>
  <div v-if="store">
    <div class="grid grid-cols-2 gap-8">
      <div class="bg-white shadow sm:rounded-lg mt-4">
        <div class="p-6 font-body-bold text-xl">
          Etapes de votre configuration
        </div>
        <div class="p-6 space-y-4">
          <button
            v-for="step in steps"
            :key="step.id"
            @click="selectedStepId = step.id"
            class="w-full py-2 px-4 bg-gray-100 text-black rounded hover:bg-gray-200"
          >
            <div class="flex justify-between">
              {{ step.name }}
              <span
                :class="btnClasses(step)"
              >
                {{ btnText(step) }}
              </span>
            </div>
          </button>
        </div>
      </div>
      <div class="bg-white shadow sm:rounded-lg mt-4">
        <div class="p-6 font-body-bold text-xl">
          {{ selectedStep.name }}
          <component
            :is="selectedStep.component"
            :store="store"
            @configurationUpdated="$emit('configurationUpdated')"
          ></component>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <p>{{ $t("Loading...") }}</p>
  </div>
</template>

<script>
import GeneralInformations from "@/views/admin/store/details/setup/GeneralInformations.vue";
import ContactInformations from "@/views/admin/store/details/setup/ContactInformations.vue";
import KycInformations from "@/views/admin/store/details/setup/KycInformations.vue";
import RepairInformations from "@/views/admin/store/details/setup/RepairInformations.vue";

export default {
  props: ["store"],

  components: {
    GeneralInformations,
    ContactInformations,
    KycInformations,
    RepairInformations,
  },

  data() {
    return {
      steps: [
        {
          id: "generalInformation",
          configStatus: "generalInformationsConfigStatus",
          name: "Informations générales",
          component: GeneralInformations,
        },
        {
          id: "contactInformation",
          configStatus: "contactInformationsConfigStatus",
          name: "Coordonnées du magasin",
          component: ContactInformations,
        },
        {
          id: "kyc",
          configStatus: "kycConfigStatus",
          name: "KYC",
          component: KycInformations,
        },
        {
          id: "afterSalesService",
          configStatus: "afterSalesServiceConfigStatus",
          name: "Sav et réparations",
          component: RepairInformations,
        },
      ],
      selectedStepId: "generalInformation", // default step on init
      badge: {
        to_be_completed: {
          text: "A compléter",
          classes: "inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600",
        },
        pending_validation: {
          text: "En attente de validation",
          classes: "inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800",
        },
        validated: {
          text: "Validé",
          classes: "inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700",
        },
        rejected: {
          text: "Rejeté",
          classes: "inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700",
        },
      },
    };
  },

  computed: {
    selectedStep() {
      return this.steps.find((step) => step.id === this.selectedStepId);
    },
  },

  methods: {
    btnClasses(step) {
      const status = this.storeConfigStatus(step);
      return this.badge[status].classes;
    },
    btnText(step) {
      const status = this.storeConfigStatus(step);
      return this.badge[status].text;
    },
    storeConfigStatus(step) {
      return this.store[step.configStatus];
    },
  },
};
</script>
