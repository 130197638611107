<template>
  <div>
    <Datepicker
      :placeholder="this.placeholder"
      class="text-sm w-full rounded-md"
      v-model="date"
      month-picker
      autoApply
      :enableTimePicker="false"
      locale="fr"
      :max-date="new Date()"
      @update:model-value="handleDateSelected"
      :format="format"
    />
  </div>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import { endOfMonth, startOfMonth, subMonths } from "date-fns";

export default {
  components: { Datepicker },

  props: ["placeholder"],

  data: () => {
    return {
      date: null,
    };
  },

  computed: {
    currentMonth: function () {
      return {
        start: startOfMonth(new Date()),
        end: endOfMonth(new Date()),
      };
    },

    previousMonth: function () {
      return {
        start: startOfMonth(subMonths(new Date(), 1)),
        end: endOfMonth(subMonths(new Date(), 1)),
      };
    },
  },

  methods: {
    handleDateSelected: function (selectedDate) {
      if (!selectedDate) {
        this.$emit("newDateSelected", null, null);
        return;
      }

      const selectedMonth = new Date(selectedDate.year, selectedDate.month);
      const startDate = startOfMonth(selectedMonth);
      const endDate = endOfMonth(selectedMonth);

      this.$emit(
        "newDateSelected",
        startDate.toDateString(),
        endDate.toDateString()
      );
    },

    format: function (date) {
      const selectedMonth = new Date(date.year, date.month);
      if (
        this.previousMonth.start <= selectedMonth &&
        this.previousMonth.end >= selectedMonth
      ) {
        return "Mois précédent";
      }
      if (
        this.currentMonth.start <= selectedMonth &&
        this.currentMonth.end >= selectedMonth
      ) {
        return "Mois en cours";
      }

      const startDate = startOfMonth(selectedMonth);
      const endDate = endOfMonth(selectedMonth);
      return (
        `Du ${startDate.toLocaleDateString("fr-FR")} ` +
        `au ${endDate.toLocaleDateString("fr-FR")}`
      );
    },
  },
};
</script>
