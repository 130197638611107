<template>
  <div>
    <div class="flex items-start my-6">
      <div class="flex flex-col w-full">
        <div class="sm:w-1/3 flex justify-between">
          <p class="font-body-bold text-xl">Sélectionner une période</p>
          <button 
            @click="resetToBeginning" 
            class="text-primary-dark underline text-xs"
          >Depuis le début</button>
        </div>
        <div class="flex mb-2 w-full sm:w-1/3 flex-col">
          <Datepicker
            class="text-sm rounded-md"
            v-model="date"
            range
            multiCalendars
            v-bind:presetRanges="presetRanges"
            autoApply
            v-bind:enableTimePicker="false"
            v-bind:format="format"
            locale="fr"
            :max-date="new Date()"
            :esc-close="false"
          />
        </div>
      </div>
    </div>
    <SellerAnalytics
      v-if="selectedStoreId"
      :numberOfSellers="numberOfSellers"
      :currentMonth="currentMonth"
      :sellerStats="stats"
      :averageAndTotalStats="averageAndTotalStats"
      @storeIdSelected="onStoreIdSelected"
      @sortChanged="onSellersSortChange"
    />
    <StoreAnalytics
      v-else
      :numberOfStores="numberOfStores"
      :currentMonth="currentMonth"
      :storeStats="stats"
      :averageAndTotalStats="averageAndTotalStats"
      @storeIdSelected="onStoreIdSelected"
      @sortChanged="onStoresSortChange"
    />
  </div>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";

import StoreAnalytics from "@/components/analytics/retail/StoreAnalytics.vue";
import SellerAnalytics from "@/components/analytics/retail/SellerAnalytics.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import {
  endOfMonth,
  startOfMonth,
  subMonths,
  startOfWeek,
  subWeeks,
  endOfWeek,
} from "date-fns";
import { mapActions, mapMutations, mapState } from "vuex";
import AnalyticsService from "@/api/services/analytics";

export default {
  components: {
    Datepicker,
    StoreAnalytics,
    SellerAnalytics,
  },

  mounted() {
    if (this.signedIn) {
      this.getAnalyticsBasedOnContext();
    } else {
      this.$router.push("/signin");
    }
  },

  data: () => {
    return {
      selectedStoreId: null,
      stats: null,
      averageAndTotalStats: null,
      storesSortParams: {
        column: null,
        order: null,
      },
      sellersSortParams: {
        column: null,
        order: null,
      },
      numberOfStores: 0,
      numberOfSellers: 0,
      date: [],
      currentMonth: "",
      presetRanges: [
        {
          id: 1,
          label: "Mois en cours",
          range: [startOfMonth(new Date()), endOfMonth(new Date())],
        },
        {
          id: 2,
          label: "Semaine en cours",
          range: [
            startOfWeek(new Date(), { weekStartsOn: 1 }),
            endOfWeek(new Date(), { weekStartsOn: 1 }),
          ],
        },
        {
          id: 3,
          label: "Mois précédent",
          range: [
            startOfMonth(subMonths(new Date(), 1)),
            endOfMonth(subMonths(new Date(), 1)),
          ],
        },
        {
          id: 4,
          label: "Semaine précédente",
          range: [
            subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 1),
            subWeeks(endOfWeek(new Date(), { weekStartsOn: 1 }), 1),
          ],
        },
      ],
      comparedTo: {},
    };
  },

  computed: {
    ...mapState("auth", ["signedIn"]),
  },

  created() {
    const endDate = endOfMonth(new Date());
    const startDate = startOfMonth(new Date());
    this.date = [startDate, endDate];
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    ...mapMutations("store", ["setIsLoading"]),

    format: function (date) {
      const startDate = date[0];
      const endDate = date[1];

      const startDay = startDate.getDate();
      const startMonth = startDate.getMonth() + 1;
      const startYear = startDate.getFullYear();

      const endDay = endDate.getDate();
      const endMonth = endDate.getMonth() + 1;
      const endYear = endDate.getFullYear();

      if (startDate && endDate) {
        if (
          startDate instanceof Date &&
          endDate instanceof Date &&
          startDate.getTime() === startDate.getTime() &&
          endDate.getTime() === endDate.getTime()
        ) {
          if (
            this.compareDate(startDate, startOfMonth(new Date())) &&
            this.compareDate(endDate, endOfMonth(new Date()))
          ) {
            return "Mois en cours";
          }
          if (
            this.compareDate(
              startDate,
              startOfMonth(subMonths(new Date(), 1))
            ) &&
            this.compareDate(endDate, endOfMonth(subMonths(new Date(), 1)))
          ) {
            return "Mois précédent";
          }
          if (
            this.compareDate(
              startDate,
              startOfWeek(new Date(), { weekStartsOn: 1 })
            ) &&
            this.compareDate(
              endDate,
              endOfWeek(new Date(), { weekStartsOn: 1 })
            )
          ) {
            return "Semaine en cours";
          }
          if (
            this.compareDate(
              startDate,
              subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 1)
            ) &&
            this.compareDate(
              endDate,
              subWeeks(endOfWeek(new Date(), { weekStartsOn: 1 }), 1)
            )
          ) {
            return "Semaine précédente";
          }
        }
      }

      return `From ${startDay}/${startMonth}/${startYear} to ${endDay}/${endMonth}/${endYear}`;
    },

    compareDate(date1, date2) {
      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
      );
    },

    getSellerAnalytics: async function (storeId) {
      this.setIsLoading(true);
      try {
        const start = Array.isArray(this.date) && this.date[0] ? this.date[0] : null;
        const end = Array.isArray(this.date) && this.date[1] ? this.date[1] : null;

        const sortParams = this.retrieveSortParams();

        const response = await AnalyticsService.getRetailSellersData(
          storeId,
          start,
          end,
          sortParams
        );

        const data = response.data;
        this.stats = data.stats;
        this.numberOfStores = data.totalStores;
        this.numberOfSellers = data.totalSellers;
        this.averageAndTotalStats = [data.average, data.total];
        this.numberOfStores = 0;
        this.selectedStoreId = storeId;
      } catch (error) {
        console.log(error);
        this.setIsLoading(false);
        this.notify({
          category: "simple",
          type: "error",
        });
        this.onStoreIdSelected(null);
      }
      this.setIsLoading(false);
    },

    getStoreAnalytics: async function () {
      this.setIsLoading(true);

      try {
        const start = Array.isArray(this.date) && this.date[0] ? this.date[0] : null;
        const end = Array.isArray(this.date) && this.date[1] ? this.date[1] : null;

        const sortParams = this.retrieveSortParams();

        const response = await AnalyticsService.getRetailSalesData(
          start,
          end,
          sortParams
        );

        const data = response.data;
        this.stats = data.stats;
        this.numberOfStores = data.totalStores;
        this.numberOfSellers = data.totalSellers;
        this.averageAndTotalStats = [data.average, data.total];
        this.selectedStoreId = null;
      } catch (error) {
        console.log(error);
      }

      this.setIsLoading(false);
    },

    getAnalyticsBasedOnContext: async function () {
      this.selectedStoreId === null
        ? await this.getStoreAnalytics()
        : await this.getSellerAnalytics(this.selectedStoreId);
    },

    onStoreIdSelected(storeId) {
      if (storeId === null) {
        this.numberOfStores = 0;
        this.getStoreAnalytics();
      } else {
        this.getSellerAnalytics(storeId);
      }
    },

    updateCurrentMonth: function (endDate) {
      const monthNames = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ];
      const month = monthNames[endDate.getMonth()];
      const year = endDate.getFullYear();
      this.currentMonth = `${month} ${year}`;
    },

    onStoresSortChange: function (sortParams) {
      this.storesSortParams = sortParams;
      this.getStoreAnalytics();
    },

    onSellersSortChange: function (sortParams) {
      this.sellersSortParams = sortParams;
      this.getSellerAnalytics(this.selectedStoreId);
    },

    resetToBeginning() {
      this.date = null;
      this.getAnalyticsBasedOnContext();
    },

    retrieveSortParams() {
      if (this.selectedStoreId) {
        return this.sellersSortParams;
      } else {
        return this.storesSortParams;
      }
    },
  },
  watch: {
    date: function (newDate) {
      if (Array.isArray(newDate) && newDate.length > 0 && newDate[0] && newDate[1]) {
        this.updateCurrentMonth(newDate[1]);
      }
      this.getAnalyticsBasedOnContext();
    },
  },
};
</script>
