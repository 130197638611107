<template>
  <div>
    <div>
      <FormField
        id="organizationName"
        label="Nom du réseau"
        name="organizationName"
        placeholder="Nom du réseau"
        disabled
        v-model="formData.organizationName"
      />
      <FormField
        id="businessName"
        label="Raison sociale"
        name="businessName"
        placeholder="Raison sociale"
        v-model="formData.businessName"
      />
      <FormField
        id="salesChannel"
        label="Nom commercial"
        name="salesChannel"
        placeholder="Nom commercial"
        v-model="formData.salesChannel"
      />
      <FormField
        id="billingAddress"
        label="Adresse postale"
        name="billingAddress"
        placeholder="Adresse postale"
        v-model="formData.billingAddress"
      />
      <Selector
        id="revenue_range"
        label="Revenue range"
        name="revenue_range"
        v-model="formData.revenueRange"
        :options="revenueRanges"
      />
      <Selector
        id="sellersNumber"
        label="Nombre de vendeurs"
        name="sellersNumber"
        placeholder="Nombre de vendeurs"
        v-model="formData.sellersNumber"
        :options="sellersNumbers"
      />
      <MultiSelect
        id="storeVerticals"
        label="Verticales"
        placeholder="Sélectionner une ou des verticales"
        v-model="formData.storeVerticals"
        :options="storeVerticalsOptions"
      />
      <FormField
        v-if="isCustomStoreVerticalSelected"
        id="otherStoreVertical"
        label="Autre verticale:"
        name="otherStoreVertical"
        v-model="customStoreVerticalName"
      />
    </div>
    <div class="mt-4 flex justify-end">
      <GenericButton @click="updateGeneralInformations">
        Valider
      </GenericButton>
    </div>
  </div>
</template>

<script>
import GenericButton from "@/components/utils/GenericButton.vue";
import Selector from "@/components/utils/Selector.vue";
import MultiSelect from "@/components/utils/MultiSelect.vue";
import FormField  from "@/components/utils/FormField.vue";
import StoreService from "@/api/services/store";
import { mapActions } from "vuex";

export default {
  props: ["store"],

  components: {
    GenericButton,
    Selector,
    FormField,
    MultiSelect
  },

  data() {
    return {
      formData: {
        organizationName: this.store.organizationName || "",
        businessName: this.store.businessName || "",
        salesChannel: this.store.salesChannel || "",
        billingAddress: this.store.billingAddress || "",
        revenueRange: this.store.revenueRange || "",
        sellersNumber: this.store.sellersNumber || "1",
        storeVerticals: this.store.storeVerticals || [],
      },
      revenueRanges: [],
      sellersNumbers: [],
      storeVerticals: [],
      customStoreVerticalOption: "Autre (Préciser)",
      customStoreVerticalName: "",
    };
  },

  created() {
    this.fetchOptions();
  },

  emits: ['configurationUpdated'],

  computed: {
    storeVerticalsOptions() {
      const options = this.storeVerticals;
      this.formData.storeVerticals.forEach((storeVertical) => {
        if (!options.find((option) => option.value === storeVertical)) {
          options.push({ label: storeVertical, value: storeVertical });
        }
      });
      options.push({ label: this.customStoreVerticalOption, value: this.customStoreVerticalOption });
      return this.deduplicate(options);
    },
    isCustomStoreVerticalSelected() {
      return this.formData.storeVerticals.includes(this.customStoreVerticalOption);
    },
    storeVerticalsSanitized() {
      const verticals = this.formData.storeVerticals.filter((value) => value !== this.customStoreVerticalOption);
      if (this.isCustomStoreVerticalSelected) {
        verticals.push(this.customStoreVerticalName);
      }
      return verticals;
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),
    async fetchOptions() {
      const storeId = this.store.id;
      try {
        const response = await StoreService.getStoreGeneralInformationsOptions(storeId);
        this.formData.storeVerticals = response.data.store.store_verticals;
        this.revenueRanges = response.data.revenue_ranges;
        this.sellersNumbers = response.data.sellers_numbers;
        this.storeVerticals = response.data.store_verticals;
      } catch (error) {
        this.notify({
          category: "simple",
          type: "error",
          title: "Une erreur s'est produite lors du chargement du formulaire",
          text: error.response?.data?.error || error.message,
        });
      }
    },
    async updateGeneralInformations() {
      const storeId = this.store.id;
      try {
        const payload = {
          ...this.formData,
          storeVerticals: this.storeVerticalsSanitized,
        };
        await StoreService.updateStoreGeneralInformations(storeId, payload);
        this.$emit("configurationUpdated");
        this.notify({
          category: "simple",
          type: "success",
          title: "Informations mises à jour",
          text: "Les informations générales ont été mises à jour avec succès",
        });
      } catch (error) {
        this.notify({
          category: "simple",
          type: "error",
          title: "Une erreur s'est produite",
          text: error.response?.data?.error || error.message,
        });
      }
    },
    deduplicate(options) {
      return [...new Map(options.map((option) => [option.value, option])).values()];
    },
  }
};
</script>
