<template>
  <div>
    <div
      class="bg-yellow-50 border-l-4 border-yellow-400 text-yellow-700 p-4 mt-5 mb-4 sm:mt-6"
    >
      <div class="flex flex-row items-center">
        <ExclamationIcon class="w-8 h-8 text-yellow-400" />
        <p class="ml-4 font-bold">Une action est requise de votre part.</p>
      </div>
      <div class="pl-12">
        <div v-if="this.isInvoiceUploadRequired">
          <InvoiceUploadArea v-bind:admin-view="this.adminView" />
        </div>
        <div v-else-if="this.isQuotationUploadRequired">
          <QuotationUploadArea v-bind:admin-view="this.adminView" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ExclamationIcon } from "@heroicons/vue/solid";
import QuotationUploadArea from "@/components/claim/details/quotation/QuotationUploadArea.vue";
import InvoiceUploadArea from "@/components/claim/details/invoice/InvoiceUploadArea.vue";

export default {
  components: {
    InvoiceUploadArea,
    QuotationUploadArea,
    ExclamationIcon,
  },

  props: ["claim", "adminView"],

  computed: {
    isInvoiceUploadRequired() {
      return this.claim.requiredAction === "invoice_upload_required";
    },
    isQuotationUploadRequired() {
      return this.claim.requiredAction === "quotation_repair_upload_required";
    },
  },
};
</script>
