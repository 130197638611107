<template>
  <div>
    <slot></slot>
    <form>
      <div class="sm:col-span-6 px-6 pb-6 pt-2">
        <div
          class="flex justify-center px-6 pt-5 pb-6 border-2 border-primary border-dashed rounded-md"
        >
          <div class="space-y-1 text-center">
            <svg
              class="mx-auto h-12 w-12 text-primary-dark"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div class="flex text-sm text-primary-dark">
              <label
                :id="`file-${this.question}`"
                class="relative cursor-pointer bg-transparent rounded-md font-medium text-black-600 hover:text-black-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-black-600"
              >
                <div v-if="this.multiple">
                  <span>{{ $t("Upload one or more files") }}</span>
                  <input
                    :id="`file-${this.question}`"
                    type="file"
                    ref="input-multiple"
                    class="sr-only"
                    multiple
                    :placeholder="this.question"
                    @change="this.handleFileUpload"
                  />
                </div>
                <div v-else>
                  <span>{{ $t("Upload a file") }}</span>
                  <input
                    :id="`file-${this.question}`"
                    type="file"
                    ref="input-single"
                    class="sr-only"
                    :placeholder="this.question"
                    v-on:change="this.handleFileUpload"
                  />
                </div>
              </label>
              <p class="pl-1">{{ $t("or drag and drop") }}</p>
            </div>
            <p class="text-xs text-primary-dark">
              {{ $t("PNG, JPG, GIF up to 10MB") }}
            </p>
          </div>
        </div>
      </div>
    </form>
    <div>
      <UploadedFilesDisplay
        v-if="this.isValid"
        v-bind:files="this.files"
        @removeFile="this.removeFile"
      />
      <button
        class="text-center mt-2 mb-3 relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium text-button-font-primary bg-primary transition duration-300 transform hover:scale-105 rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
        v-if="this.buttonTextPresent && this.isValid"
        @click="sendFiles"
      >
        {{ this.sendButtonText }}
      </button>
    </div>
  </div>
</template>

<script>
import UploadedFilesDisplay from "@/components/upload/display/UploadedFilesDisplay";

export default {
  components: {
    UploadedFilesDisplay,
  },

  data() {
    return {
      files: null,
    };
  },

  computed: {
    isValid() {
      return this.files !== null && this.files.length !== 0;
    },

    buttonTextPresent() {
      return !(this.sendButtonText == null || this.sendButtonText.length === 0);
    },
  },

  props: {
    question: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      required: true,
    },
    sendButtonText: {
      type: String,
      required: false,
    },
  },

  emits: ["sendFiles", "files"],

  methods: {
    handleFileUpload: function (event) {
      if (this.files === null) {
        this.files = [];
      }
      const fileList = event.target.files;

      for (let i = 0; i < fileList.length; i++) {
        this.files.push(fileList[i]);
      }
    },

    removeFile: function (index) {
      if (this.multiple) {
        this.$refs["input-multiple"].value = null;
      } else {
        this.$refs["input-single"].value = null;
      }

      return this.files.splice(index, 1);
    },

    sendFiles: function () {
      this.$emit("sendFiles", this.multiple ? this.files : this.files[0]);
    },
  },

  watch: {
    files: function (newFiles) {
      this.$emit("files", newFiles);
    },
  },
};
</script>
