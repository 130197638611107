<template>
  <div>
    <div>
      <Selector
        id="repair_workshop"
        label="Atelier de réparation"
        name="repair_workshop"
        v-model="formData.repairWorkshop"
        :options="repairWorkshops"
      />
      <FormField
        v-if="formData.repairWorkshop == 'true'"
        id="repair_workshop_email"
        label="Email de l’atelier de réparation"
        name="repair_workshop_email"
        v-model="formData.repairWorkshopEmail"
      />
    </div>
    <div class="mt-4 flex justify-end">
      <Loading v-if="isLoading"/>
      <GenericButton v-else @click="updateAfterSalesService">
        Valider
      </GenericButton>
    </div>
  </div>
</template>

<script>
import GenericButton from "@/components/utils/GenericButton.vue";
import Selector from "@/components/utils/Selector.vue";
import FormField  from "@/components/utils/FormField.vue";
import StoreService from "@/api/services/store";
import { mapActions } from "vuex";

export default {
  props: ["store"],

  components: {
    GenericButton,
    Selector,
    FormField,
  },

  data() {
    return {
      isLoading: false,
      formData: {
        repairWorkshop: false,
        repairWorkshopEmail: ""
      },
      repairWorkshops: [
        { value: true, label: "Oui" },
        { value: false, label: "Non" }
      ]
    };
  },

  created() {
    this.setupFormData();
  },
  emits: ['configurationUpdated'],
  methods: {
    ...mapActions("notifications", ["notify"]),
    async setupFormData() {
      this.isLoading = true;
      try {
        const response = await StoreService.getAfterSalesService(this.store.id);
        this.formData.repairWorkshop = response.data.repair_workshop;
        this.formData.repairWorkshopEmail = response.data.repair_workshop_email;
      } catch (error) {
        this.notify({
          category: "simple",
          type: "error",
          title: "Une erreur s'est produite lors du chargement du formulaire",
          text: error.response?.data?.error || error.message,
        });
      }
      this.isLoading = false;
    },
    async updateAfterSalesService() {
      const storeId = this.store.id;
      try {
        await StoreService.updateAfterSalesService(storeId, this.formData);
        this.$emit("configurationUpdated");
        this.notify({
          category: "simple",
          type: "success",
          title: "Informations mises à jour",
          text: "Les informations ont été mises à jour avec succès",
        });
      } catch (error) {
        this.notify({
          category: "simple",
          type: "error",
          title: "Une erreur s'est produite",
          text: error.response?.data?.error || error.message,
        });
      }
    },
  }
};
</script>
