<template>
  <div class="flow-root shadow overflow-hidden sm:rounded-lg">
    <div class="px-4 py-5 sm:px-6 bg-gray">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        Traitement du sinistre
      </h3>
    </div>
    <ul role="list" class="bg-white items-center pt-8">
      <li
        v-for="(event, eventIdx) in this.claim.historicalData"
        :key="eventIdx"
      >
        <div class="relative pb-8 m-auto ml-10 mr-10">
          <span
            v-if="isLastStatus(eventIdx)"
            class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          />
          <div class="relative flex space-x-3">
            <div>
              <span
                :class="[
                  this.getRelatedIconBackground(event.status),
                  'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white',
                ]"
              >
                <component
                  :is="this.getRelatedIcon(event.status)"
                  class="h-5 w-5 text-white"
                  aria-hidden="true"
                />
              </span>
            </div>
            <div
              class="flex flex-col sm:flex-row min-w-0 flex-1 justify-between space-x-4 pt-1.5"
            >
              <div>
                <p
                  v-bind:class="[
                    'text-sm text-gray-500',
                    isLastStatus(eventIdx) ? 'font-bold' : null,
                  ]"
                >
                  {{ event.relatedText }}
                </p>
              </div>
              <div
                v-bind:class="[
                  'whitespace-nowrap text-right text-sm text-gray-500',
                  isLastStatus(eventIdx) ? 'font-bold' : null,
                ]"
              >
                <p>{{ event.date }}</p>
              </div>
            </div>
          </div>
          <div class="relative ml-11" v-if="event.merchantNotification">
            <p class="text-xs">{{ event.merchantNotification }}</p>
          </div>
          <div class="relative ml-11" v-if="event.claimManagerNotes">
            <p class="text-xs text-gray-500">{{ event.claimManagerNotes }}</p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import {
  BanIcon,
  CashIcon,
  CheckIcon,
  ChevronDoubleUpIcon,
  CogIcon,
  DocumentTextIcon,
  StarIcon,
} from "@heroicons/vue/solid";

export default {
  components: {
    BanIcon,
    CashIcon,
    CheckIcon,
    ChevronDoubleUpIcon,
    CogIcon,
    DocumentTextIcon,
    StarIcon,
  },
  props: ["claim"],

  methods: {
    isLastStatus: function (eventIdx) {
      return eventIdx === 0;
    },

    getRelatedIcon: function (event) {
      let result = StarIcon;

      switch (event) {
        case "to_be_approved":
          result = ChevronDoubleUpIcon;
          break;
        case "denied":
          result = BanIcon;
          break;
        case "processing":
          result = DocumentTextIcon;
          break;
        case "pending":
          result = DocumentTextIcon;
          break;
        case "approved":
          result = CogIcon;
          break;
        case "cancelled":
          result = BanIcon;
          break;
        case "resolved":
          result = CheckIcon;
          break;
      }

      return result;
    },

    getRelatedIconBackground: function (event) {
      let result = "bg-gray-400";

      switch (event) {
        case "to_be_approved":
          result = "bg-yellow-400";
          break;
        case "approved":
          result = "bg-green-200";
          break;
        case "denied":
          result = "bg-red-400";
          break;
        case "pending":
          result = "bg-purple-400";
          break;
        case "cancelled":
          result = "bg-red-400";
          break;
        case "processing":
          result = "bg-yellow-400";
          break;
        case "resolved":
          result = "bg-green-400";
          break;
      }

      return result;
    },
  },
};
</script>
