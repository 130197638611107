<template>
  <div class="mt-8" style="min-height: 400px">
    <p class="text-xl font-heading-1 pb-4">Informations de paiement</p>
    <div v-if="offerPrice">
      <form id="payment-form" @submit.prevent="emitConfirmationRequest">
        <div id="payment-element"></div>
        <p class="mt-1 text-xs text-red-400">
          Pour les paiements en SEPA, la confirmation du paiement prend en
          moyenne entre 3 et 5 jours.
        </p>
        <div id="payment-message" class="hidden"></div>
        <div class="flex justify-center">
          <button
            type="button"
            class="mt-8 inline-flex items-center rounded-2xl border border-transparent text-button-font-primary bg-primary uppercase px-6 py-3 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
            @click="$emit('sendConsentCode')"
          >
            Envoyer le code de confirmation
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import { isArrayFull } from "@/utils/validation";
import { mapActions } from "vuex";
export default {
  props: {
    offerPrice: Object,
    discount: Object,
  },
  computed: {
    isPromotionOnMonths() {
      if (isArrayFull(this.discount?.type)) {
        return (
          this.discount.type.split("_")[0] === "months" &&
          this.offerPrice.recurring
        );
      } else {
        return false;
      }
    },
    isOffered() {
      if (isArrayFull(this.discount?.type)) {
        return (
          this.discount.type === "months_offered" && this.offerPrice.recurring
        );
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      stripeKey: `${process.env.VUE_APP_STRIPE_KEY}`,
    };
  },
  watch: {
    offerPrice: function (newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        this.initialize();
        this.checkStatus();
        document
          .querySelector("#payment-form")
          .addEventListener("submit", this.handleSubmit);
        this.emailAddress = "";
      }
    },
  },
  async mounted() {
    this.stripe = await loadStripe(this.stripeKey, { locale: "fr" });
    this.initialize();
    this.checkStatus();
    document
      .querySelector("#payment-form")
      .addEventListener("submit", this.handleSubmit);
    this.emailAddress = "";
  },
  methods: {
    ...mapActions("notifications", ["notify"]),

    emitConfirmationRequest() {
      this.$emit("requestConfirmationCode");
    },

    async initialize() {
      const clientSecret = this.offerPrice.stripe_client;
      const appearance = {
        theme: "stripe",
        variables: {
          borderRadius: "1rem",
          fontSizeSm: "14px",
          fontLineHeight: "10px",
          spacingUnit: "3px",
          spacingGridColumn: "1.5rem",
          fontSizeBase: "0.875rem",
        },
        rules: {
          ".Input": {
            border: "1px solid #9ca3af",
          },
          ".Input::placeholder": {
            "font-size": "0.875rem",
          },
        },
      };

      this.elements = this.stripe.elements({ appearance, clientSecret });
      const paymentElementOptions = {
        layout: "tabs",
      };
      const paymentElement = this.elements.create(
        "payment",
        paymentElementOptions
      );
      paymentElement.mount("#payment-element");
    },

    async handleSubmit() {
      const elements = this.elements;
      let result = null;

      if (
        this.discount.type === "months_offered" &&
        this.offerPrice.recurring
      ) {
        result = await this.stripe.confirmSetup({
          elements,
          redirect: "if_required",
          confirmParams: {
            setup_intent: this.setup_intent_client_secret,
            return_url: `${window.location.origin}/`,
          },
        });
      } else {
        result = await this.stripe.confirmPayment({
          elements,
          redirect: "if_required",
          confirmParams: {
            receipt_email: this.emailAddress,
            return_url: `${window.location.origin}/`,
          },
        });
      }

      let error = result.error;

      if (
        !error &&
        (result.paymentIntent?.status === "succeeded" ||
          result.paymentIntent?.status === "processing" ||
          result.setupIntent?.status === "succeeded" ||
          result.setupIntent?.status === "processing")
      ) {
        this.$emit("paymentSuccess", true);
      } else if (error) {
        console.log(error);
        this.$emit("showConfirmationStep", false);
        this.showMessage(error.message);
      }
    },

    async checkStatus() {
      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );
      if (!clientSecret) {
        return;
      }
      const { paymentIntent } = await this.stripe.retrievePaymentIntent(
        clientSecret
      );
      switch (paymentIntent.status) {
        case "succeeded":
          this.showMessage("Payment succeeded!");
          break;
        case "processing":
          this.showMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          this.showMessage(
            "Your payment was not successful, please try again."
          );
          break;
        default:
          this.showMessage("Something went wrong.");
          break;
      }
    },

    showMessage(messageText) {
      const messageContainer = document.querySelector("#payment-message");
      messageContainer.classList.remove("hidden");
      messageContainer.textContent = messageText;
      setTimeout(function () {
        messageContainer.classList.add("hidden");
        messageText.textContent = "";
      }, 10000);
    },

    setLoading(isLoading) {
      if (isLoading) {
        // Disable the button and show a spinner
        document.querySelector("#submit").disabled = true;
        document.querySelector("#spinner").classList.remove("hidden");
        document.querySelector("#button-text").classList.add("hidden");
        document.querySelector("#loading").classList.remove("hidden");
      } else {
        document.querySelector("#submit").disabled = false;
        document.querySelector("#spinner").classList.add("hidden");
        document.querySelector("#button-text").classList.remove("hidden");
        document.querySelector("#loading").classList.add("hidden");
      }
    },
  },
};
</script>
